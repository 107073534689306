import { atom } from 'jotai';
import { Column } from '@/types';

export const columnFilterAtom = atom<{ [c in Column]: boolean }>({
  jaTitle: true,
  enTitle: false,
  packName: false,
  difficulty: true,
  level: true,
  constant: true,
  notes: false,
  score: true,
  potential: true,
  clearLamp: true,
});
