import { atom } from 'jotai';
import { Level } from '@/types';

export const levelFilterAtom = atom<{ [l in Level]: boolean }>({
  '1': true,
  '2': true,
  '3': true,
  '4': true,
  '5': true,
  '6': true,
  '7': true,
  '8': true,
  '9': true,
  '9+': true,
  '10': true,
  '10+': true,
  '11': true,
  '12': true,
});
