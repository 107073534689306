import { generateFumenData } from './utils';

export const VERSION = 'v4.3.2';
export const { fumenKeys, fumenData } = generateFumenData([
  {
    id: 1,
    jaTitle: 'DataErr0r',
    enTitle: 'DataErr0r',
    pastLevel: '3',
    pastNotes: 502,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 785,
    presentConstant: 7.5,
    futureLevel: '9',
    futureNotes: 955,
    futureConstant: 9.5,
    packName: 'Memory Archive',
  },
  {
    id: 2,
    jaTitle: 'CROSS†SOUL',
    enTitle: 'CROSS†SOUL',
    pastLevel: '4',
    pastNotes: 606,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 823,
    presentConstant: 7.0,
    futureLevel: '9',
    futureNotes: 1081,
    futureConstant: 9.4,
    packName: 'Memory Archive',
  },
  {
    id: 3,
    jaTitle: 'Your voice so... feat. Such',
    enTitle: 'Your voice so... feat. Such',
    pastLevel: '3',
    pastNotes: 469,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 677,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1013,
    futureConstant: 9.4,
    packName: 'Memory Archive',
  },
  {
    id: 4,
    jaTitle: '不浄な白い鳥',
    enTitle: 'Impure Bird',
    pastLevel: '2',
    pastNotes: 350,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 518,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 805,
    futureConstant: 9.4,
    packName: 'Memory Archive',
  },
  {
    id: 5,
    jaTitle: 'Auxesia',
    enTitle: 'Auxesia',
    pastLevel: '3',
    pastNotes: 385,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 648,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1000,
    futureConstant: 9.3,
    packName: 'Memory Archive',
  },
  {
    id: 6,
    jaTitle: 'Modelista',
    enTitle: 'Modelista',
    pastLevel: '3',
    pastNotes: 418,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 691,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1010,
    futureConstant: 10,
    packName: 'Memory Archive',
  },
  {
    id: 7,
    jaTitle: 'Surrender',
    enTitle: 'Surrender',
    pastLevel: '3',
    pastNotes: 550,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 721,
    presentConstant: 6.5,
    futureLevel: '8',
    futureNotes: 925,
    futureConstant: 8.8,
    packName: 'Memory Archive',
  },
  {
    id: 8,
    jaTitle: '夜桜吹雪',
    enTitle: 'Yosakura Fubuki',
    pastLevel: '4',
    pastNotes: 416,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 582,
    presentConstant: 7.0,
    futureLevel: '9',
    futureNotes: 931,
    futureConstant: 9.4,
    packName: 'Memory Archive',
  },
  {
    id: 9,
    jaTitle: 'Metallic Punisher',
    enTitle: 'Metallic Punisher',
    pastLevel: '3',
    pastNotes: 500,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 846,
    presentConstant: 7.0,
    futureLevel: '10',
    futureNotes: 1238,
    futureConstant: 10.3,
    packName: 'Memory Archive',
  },
  {
    id: 10,
    jaTitle: 'carmine:scythe',
    enTitle: 'carmine:scythe',
    pastLevel: '4',
    pastNotes: 493,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 710,
    presentConstant: 7.5,
    futureLevel: '9',
    futureNotes: 1164,
    futureConstant: 9.6,
    packName: 'Memory Archive',
  },
  {
    id: 11,
    jaTitle: 'γuarδina',
    enTitle: 'γuarδina',
    pastLevel: '4',
    pastNotes: 507,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 678,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1120,
    futureConstant: 10.4,
    packName: 'Memory Archive',
  },
  {
    id: 12,
    jaTitle: 'Be There',
    enTitle: 'Be There',
    pastLevel: '4',
    pastNotes: 592,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 792,
    presentConstant: 7.5,
    futureLevel: '9',
    futureNotes: 982,
    futureConstant: 9.4,
    packName: 'Memory Archive',
  },
  {
    id: 13,
    jaTitle: 'Call My Name feat. Yukacco',
    enTitle: 'Call My Name feat. Yukacco',
    pastLevel: '3',
    pastNotes: 591,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 653,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 921,
    futureConstant: 8.7,
    packName: 'Memory Archive',
  },
  {
    id: 14,
    jaTitle: 'dropdead',
    enTitle: 'dropdead',
    pastLevel: '1',
    pastNotes: 44,
    pastConstant: 1.5,
    presentLevel: '9',
    presentNotes: 1323,
    presentConstant: 9.5,
    futureLevel: '8',
    futureNotes: 823,
    futureConstant: 9.1,
    packName: 'Memory Archive',
  },
  {
    id: 14,
    jaTitle: 'overdead.',
    enTitle: 'overdead.',
    beyondLevel: '10',
    beyondNotes: 1503,
    beyondConstant: 10.5,
    packName: 'Memory Archive',
  },
  {
    id: 15,
    jaTitle: 'Fallensquare',
    enTitle: 'Fallensquare',
    pastLevel: '3',
    pastNotes: 316,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 486,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 703,
    futureConstant: 9.6,
    packName: 'Memory Archive',
  },
  {
    id: 16,
    jaTitle: 'Alexandrite',
    enTitle: 'Alexandrite',
    pastLevel: '4',
    pastNotes: 507,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 699,
    presentConstant: 7.0,
    futureLevel: '10',
    futureNotes: 1040,
    futureConstant: 10,
    packName: 'Memory Archive',
  },
  {
    id: 17,
    jaTitle: 'Astral tale',
    enTitle: 'Astral tale',
    pastLevel: '4',
    pastNotes: 445,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 642,
    presentConstant: 7.0,
    futureLevel: '9',
    futureNotes: 884,
    futureConstant: 9.6,
    packName: 'Memory Archive',
  },
  {
    id: 18,
    jaTitle: 'Phantasia',
    enTitle: 'Phantasia',
    pastLevel: '4',
    pastNotes: 544,
    pastConstant: 4,
    presentLevel: '5',
    presentNotes: 579,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 952,
    futureConstant: 9.2,
    packName: 'Memory Archive',
  },
  {
    id: 19,
    jaTitle: 'Empire of Winter',
    enTitle: 'Empire of Winter',
    pastLevel: '3',
    pastNotes: 484,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 662,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 920,
    futureConstant: 9,
    packName: 'Memory Archive',
  },
  {
    id: 20,
    jaTitle: 'Libertas',
    enTitle: 'Libertas',
    pastLevel: '3',
    pastNotes: 476,
    pastConstant: 3.5,
    presentLevel: '5',
    presentNotes: 514,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 947,
    futureConstant: 9.2,
    packName: 'Memory Archive',
  },
  {
    id: 21,
    jaTitle: 'Dot to Dot feat. shully',
    enTitle: 'Dot to Dot feat. shully',
    pastLevel: '3',
    pastNotes: 453,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 522,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 739,
    futureConstant: 8.3,
    packName: 'Memory Archive',
  },
  {
    id: 22,
    jaTitle: 'Dreadnought',
    enTitle: 'Dreadnought',
    pastLevel: '4',
    pastNotes: 715,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 897,
    presentConstant: 7.0,
    futureLevel: '9+',
    futureNotes: 1099,
    futureConstant: 9.7,
    packName: 'Memory Archive',
  },
  {
    id: 23,
    jaTitle: 'Mirzam',
    enTitle: 'Mirzam',
    pastLevel: '4',
    pastNotes: 662,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 885,
    presentConstant: 7.0,
    futureLevel: '10',
    futureNotes: 1303,
    futureConstant: 10,
    packName: 'Memory Archive',
  },
  {
    id: 24,
    jaTitle: 'Heavenly caress',
    enTitle: 'Heavenly caress',
    pastLevel: '3',
    pastNotes: 855,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 909,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1560,
    futureConstant: 9.8,
    packName: 'Memory Archive',
  },
  {
    id: 25,
    jaTitle: 'Filament',
    enTitle: 'Filament',
    pastLevel: '4',
    pastNotes: 582,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 780,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 991,
    futureConstant: 9.7,
    packName: 'Memory Archive',
  },
  {
    id: 26,
    jaTitle: 'Avant Raze',
    enTitle: 'Avant Raze',
    pastLevel: '3',
    pastNotes: 580,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 727,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1125,
    futureConstant: 9.6,
    packName: 'Memory Archive',
  },
  {
    id: 27,
    jaTitle: 'BATTLE NO.1',
    enTitle: 'BATTLE NO.1',
    pastLevel: '3',
    pastNotes: 476,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 677,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1042,
    futureConstant: 9.7,
    packName: 'Memory Archive',
  },
  {
    id: 28,
    jaTitle: "La'qryma of the Wasteland",
    enTitle: "La'qryma of the Wasteland",
    pastLevel: '3',
    pastNotes: 447,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 651,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 956,
    futureConstant: 9.1,
    beyondLevel: '9+',
    beyondConstant: 9.9,
    beyondNotes: 1161,
    packName: 'Memory Archive',
  },
  {
    id: 29,
    jaTitle: 'Einherjar Joker',
    enTitle: 'Einherjar Joker',
    pastLevel: '4',
    pastNotes: 582,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 767,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1159,
    futureConstant: 9.8,
    packName: 'Memory Archive',
  },
  {
    id: 30,
    jaTitle: 'IZANA',
    enTitle: 'IZANA',
    pastLevel: '5',
    pastNotes: 609,
    pastConstant: 5,
    presentLevel: '8',
    presentNotes: 836,
    presentConstant: 8.3,
    futureLevel: '10',
    futureNotes: 976,
    futureConstant: 10.3,
    packName: 'Memory Archive',
  },
  {
    id: 31,
    jaTitle: '最強STRONGER',
    enTitle: 'SAIKYO STRONGER',
    pastLevel: '5',
    pastNotes: 654,
    pastConstant: 5.5,
    presentLevel: '9',
    presentNotes: 1067,
    presentConstant: 9.4,
    futureLevel: '11',
    futureNotes: 1384,
    futureConstant: 11,
    packName: 'Memory Archive',
  },
  {
    id: 32,
    jaTitle: 'amygdata',
    enTitle: 'amygdata',
    pastLevel: '4',
    pastNotes: 504,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 711,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1199,
    futureConstant: 9.7,
    packName: 'Memory Archive',
  },
  {
    id: 33,
    jaTitle: 'Altale',
    enTitle: 'Altale',
    pastLevel: '2',
    pastNotes: 357,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 424,
    presentConstant: 5.5,
    futureLevel: '9+',
    futureNotes: 690,
    futureConstant: 9.7,
    packName: 'Memory Archive',
  },
  {
    id: 34,
    jaTitle: 'Feels So Right feat. Renko',
    enTitle: 'Feels So Right feat. Renko',
    pastLevel: '3',
    pastNotes: 594,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 636,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 947,
    futureConstant: 9.3,
    packName: 'Memory Archive',
  },
  {
    id: 35,
    jaTitle: 'Scarlet Cage',
    enTitle: 'Scarlet Cage',
    pastLevel: '4',
    pastNotes: 570,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 711,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1195,
    futureConstant: 9.8,
    packName: 'Memory Archive',
  },
  {
    id: 36,
    jaTitle: 'Teriqma',
    enTitle: 'Teriqma',
    pastLevel: '3',
    pastNotes: 345,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 579,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 873,
    futureConstant: 9.4,
    packName: 'Memory Archive',
  },
  {
    id: 37,
    jaTitle: 'MAHOROBA',
    enTitle: 'MAHOROBA',
    pastLevel: '3',
    pastNotes: 422,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 632,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 828,
    futureConstant: 9.5,
    packName: 'Memory Archive',
  },
  {
    id: 38,
    jaTitle: 'BADTEK',
    enTitle: 'BADTEK',
    pastLevel: '4',
    pastNotes: 532,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 595,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 916,
    futureConstant: 9.7,
    packName: 'Memory Archive',
  },
  {
    id: 39,
    jaTitle: 'Malicious Mischance',
    enTitle: 'Malicious Mischance',
    pastLevel: '4',
    pastNotes: 510,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 706,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1126,
    futureConstant: 10.2,
    packName: 'Memory Archive',
  },
  {
    id: 40,
    jaTitle: 'BUCHiGiRE Berserker',
    enTitle: 'BUCHiGiRE Berserker',
    pastLevel: '5',
    pastNotes: 850,
    pastConstant: 5,
    presentLevel: '8',
    presentNotes: 1046,
    presentConstant: 8.6,
    futureLevel: '10+',
    futureNotes: 1412,
    futureConstant: 10.9,
    packName: 'Memory Archive',
  },
  {
    id: 41,
    jaTitle: 'Galaxy Friends',
    enTitle: 'Galaxy Friends',
    pastLevel: '3',
    pastNotes: 474,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 572,
    presentConstant: 6.0,
    futureLevel: '9+',
    futureNotes: 1013,
    futureConstant: 9.8,
    packName: 'Memory Archive',
  },
  {
    id: 42,
    jaTitle: 'Sayonara Hatsukoi',
    enTitle: 'Sayonara Hatsukoi',
    pastLevel: '1',
    pastNotes: 205,
    pastConstant: 1.5,
    presentLevel: '4',
    presentNotes: 305,
    presentConstant: 4.5,
    futureLevel: '7',
    futureNotes: 666,
    futureConstant: 7,
    packName: 'Arcaea',
  },
  {
    id: 43,
    jaTitle: 'Fairytale',
    enTitle: 'Fairytale',
    pastLevel: '1',
    pastNotes: 336,
    pastConstant: 1,
    presentLevel: '3',
    presentNotes: 511,
    presentConstant: 3.5,
    futureLevel: '7',
    futureNotes: 782,
    futureConstant: 7,
    beyondLevel: '9',
    beyondNotes: 932,
    beyondConstant: 9.5,
    packName: 'Arcaea',
  },
  {
    id: 44,
    jaTitle: 'Vexaria',
    enTitle: 'Vexaria',
    pastLevel: '2',
    pastNotes: 369,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 534,
    presentConstant: 5,
    futureLevel: '7',
    futureNotes: 734,
    futureConstant: 7,
    beyondLevel: '8',
    beyondNotes: 785,
    beyondConstant: 8.8,
    packName: 'Arcaea',
  },
  {
    id: 45,
    jaTitle: 'Rise',
    enTitle: 'Rise',
    pastLevel: '2',
    pastNotes: 322,
    pastConstant: 2.5,
    presentLevel: '4',
    presentNotes: 599,
    presentConstant: 4,
    futureLevel: '7',
    futureNotes: 788,
    futureConstant: 7.5,
    packName: 'Arcaea',
  },
  {
    id: 46,
    jaTitle: 'Lucifer',
    enTitle: 'Lucifer',
    pastLevel: '3',
    pastNotes: 424,
    pastConstant: 3.5,
    presentLevel: '5',
    presentNotes: 506,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 861,
    futureConstant: 8.2,
    packName: 'Arcaea',
  },
  {
    id: 47,
    jaTitle: 'Snow White',
    enTitle: 'Snow White',
    pastLevel: '2',
    pastNotes: 486,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 672,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 978,
    futureConstant: 8.4,
    packName: 'Arcaea',
  },
  {
    id: 48,
    jaTitle: 'Shades of Light in a Transcendent Realm',
    enTitle: 'Shades of Light in a Transcendent Realm',
    pastLevel: '3',
    pastNotes: 517,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 789,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 1067,
    futureConstant: 8.3,
    beyondLevel: '9',
    beyondNotes: 1042,
    beyondConstant: 9,
    packName: 'Arcaea',
  },
  {
    id: 49,
    jaTitle: 'Babaroque',
    enTitle: 'Babaroque',
    pastLevel: '3',
    pastNotes: 402,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 645,
    presentConstant: 6.5,
    futureLevel: '8',
    futureNotes: 808,
    futureConstant: 8.5,
    packName: 'Arcaea',
  },
  {
    id: 50,
    jaTitle: 'Lost Civilization',
    enTitle: 'Lost Civilization',
    pastLevel: '4',
    pastNotes: 462,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 690,
    presentConstant: 7.0,
    futureLevel: '9',
    futureNotes: 986,
    futureConstant: 9.2,
    beyondLevel: '9+',
    beyondNotes: 1061,
    beyondConstant: 9.8,
    packName: 'Arcaea',
  },
  {
    id: 51,
    jaTitle: 'GOODTEK (Arcaea Edit)',
    enTitle: 'GOODTEK (Arcaea Edit)',
    pastLevel: '4',
    pastNotes: 449,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 632,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 968,
    futureConstant: 9.3,
    beyondLevel: '9+',
    beyondNotes: 1103,
    beyondConstant: 9.8,
    packName: 'Arcaea',
  },
  {
    id: 52,
    jaTitle: 'qualia -ideaesthesia-',
    enTitle: 'qualia -ideaesthesia-',
    pastLevel: '4',
    pastNotes: 550,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 875,
    presentConstant: 7.0,
    futureLevel: '9',
    futureNotes: 1022,
    futureConstant: 9.1,
    beyondLevel: '9+',
    beyondNotes: 1288,
    beyondConstant: 9.7,
    packName: 'Arcaea',
  },
  {
    id: 53,
    jaTitle: 'Dement ~after legend~',
    enTitle: 'Dement ~after legend~',
    pastLevel: '3',
    pastNotes: 548,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 756,
    presentConstant: 6,
    futureLevel: '7',
    futureNotes: 970,
    futureConstant: 7.5,
    beyondLevel: '9+',
    beyondNotes: 1040,
    beyondConstant: 9.9,
    packName: 'Arcaea',
  },
  {
    id: 54,
    jaTitle: 'Dandelion',
    enTitle: 'Dandelion',
    pastLevel: '2',
    pastNotes: 391,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 698,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 921,
    futureConstant: 8.5,
    packName: 'Arcaea',
  },
  {
    id: 55,
    jaTitle: 'Infinity Heaven',
    enTitle: 'Infinity Heaven',
    pastLevel: '1',
    pastNotes: 336,
    pastConstant: 1.5,
    presentLevel: '5',
    presentNotes: 545,
    presentConstant: 5.5,
    futureLevel: '7',
    futureNotes: 853,
    futureConstant: 7.5,
    beyondLevel: '9',
    beyondNotes: 986,
    beyondConstant: 9.6,
    packName: 'Arcaea',
  },
  {
    id: 56,
    jaTitle: 'Anökumene',
    enTitle: 'Anökumene',
    pastLevel: '2',
    pastNotes: 412,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 588,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 851,
    futureConstant: 9.2,
    packName: 'Arcaea',
  },
  {
    id: 57,
    jaTitle: 'Brand new world',
    enTitle: 'Brand new world',
    pastLevel: '2',
    pastNotes: 322,
    pastConstant: 2,
    presentLevel: '4',
    presentNotes: 432,
    presentConstant: 4,
    futureLevel: '7',
    futureNotes: 787,
    futureConstant: 7.5,
    packName: 'Arcaea',
  },
  {
    id: 58,
    jaTitle: 'Chronostasis',
    enTitle: 'Chronostasis',
    pastLevel: '3',
    pastNotes: 619,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 812,
    presentConstant: 7.5,
    futureLevel: '8',
    futureNotes: 916,
    futureConstant: 8.9,
    packName: 'Arcaea',
  },
  {
    id: 59,
    jaTitle: '神奈川電脳暗渠',
    enTitle: 'Kanagawa Cyber Culvert',
    pastLevel: '1',
    pastNotes: 375,
    pastConstant: 1,
    presentLevel: '5',
    presentNotes: 707,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 1111,
    futureConstant: 9,
    beyondLevel: '9+',
    beyondConstant: 9.8,
    beyondNotes: 1121,
    packName: 'Arcaea',
  },
  {
    id: 60,
    jaTitle: 'クロートーと星の観測者',
    enTitle: 'Clotho and the stargazer',
    pastLevel: '2',
    pastNotes: 519,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 745,
    presentConstant: 5,
    futureLevel: '7',
    futureNotes: 1021,
    futureConstant: 7.5,
    packName: 'Arcaea',
  },
  {
    id: 61,
    jaTitle: 'Ignotus',
    enTitle: 'Ignotus',
    pastLevel: '3',
    pastNotes: 579,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 809,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1225,
    futureConstant: 9.3,
    packName: 'Arcaea',
  },
  {
    id: 61,
    jaTitle: 'Ignotus Afterburn',
    enTitle: 'Ignotus Afterburn',
    beyondLevel: '9+',
    beyondNotes: 1077,
    beyondConstant: 9.9,
    packName: 'Arcaea',
  },
  {
    id: 62,
    jaTitle: 'ハルトピア ~Utopia of Spring~',
    enTitle: 'Harutopia ~Utopia of Spring~',
    pastLevel: '1',
    pastNotes: 444,
    pastConstant: 1,
    presentLevel: '4',
    presentNotes: 706,
    presentConstant: 4.5,
    futureLevel: '8',
    futureNotes: 1061,
    futureConstant: 8.5,
    packName: 'Arcaea',
  },
  {
    id: 63,
    jaTitle: 'Rabbit In The Black Room',
    enTitle: 'Rabbit In The Black Room',
    pastLevel: '2',
    pastNotes: 373,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 467,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 772,
    futureConstant: 8.4,
    packName: 'Arcaea',
  },
  {
    id: 64,
    jaTitle: 'One Last Drive',
    enTitle: 'One Last Drive',
    pastLevel: '2',
    pastNotes: 604,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 564,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 885,
    futureConstant: 8.2,
    packName: 'Arcaea',
  },
  {
    id: 65,
    jaTitle: "Dreamin' Attraction!!",
    enTitle: "Dreamin' Attraction!!",
    pastLevel: '4',
    pastNotes: 592,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 785,
    presentConstant: 7.0,
    futureLevel: '9',
    futureNotes: 1129,
    futureConstant: 9.4,
    packName: 'Arcaea',
  },
  {
    id: 66,
    jaTitle: 'Red and Blue',
    enTitle: 'Red and Blue',
    pastLevel: '4',
    pastNotes: 464,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 597,
    presentConstant: 7.5,
    futureLevel: '9',
    futureNotes: 845,
    futureConstant: 9.4,
    packName: 'Arcaea',
  },
  {
    id: 66,
    jaTitle: 'Red and Blue and Green',
    enTitle: 'Red and Blue and Green',
    beyondLevel: '10',
    beyondNotes: 1194,
    beyondConstant: 10.0,
    packName: 'Arcaea',
  },
  {
    id: 67,
    jaTitle: 'Reinvent',
    enTitle: 'Reinvent',
    pastLevel: '2',
    pastNotes: 570,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 703,
    presentConstant: 6.5,
    futureLevel: '8',
    futureNotes: 852,
    futureConstant: 8.5,
    packName: 'Arcaea',
  },
  {
    id: 68,
    jaTitle: 'Syro',
    enTitle: 'Syro',
    pastLevel: '3',
    pastNotes: 535,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 829,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1150,
    futureConstant: 9.3,
    packName: 'Arcaea',
  },
  {
    id: 69,
    jaTitle: 'Blaster',
    enTitle: 'Blaster',
    pastLevel: '4',
    pastNotes: 571,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 635,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 1002,
    futureConstant: 9.3,
    packName: 'Arcaea',
  },
  {
    id: 70,
    jaTitle: 'Cybernecia Catharsis',
    enTitle: 'Cybernecia Catharsis',
    pastLevel: '4',
    pastNotes: 377,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 655,
    presentConstant: 7.0,
    futureLevel: '9',
    futureNotes: 946,
    futureConstant: 9.5,
    packName: 'Arcaea',
  },
  {
    id: 71,
    jaTitle: 'inkar-usi',
    enTitle: 'inkar-usi',
    pastLevel: '2',
    pastNotes: 276,
    pastConstant: 2,
    presentLevel: '4',
    presentNotes: 326,
    presentConstant: 4,
    futureLevel: '7',
    futureNotes: 463,
    futureConstant: 7.5,
    packName: 'Arcaea',
  },
  {
    id: 72,
    jaTitle: 'Suomi',
    enTitle: 'Suomi',
    pastLevel: '2',
    pastNotes: 368,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 550,
    presentConstant: 5,
    futureLevel: '7',
    futureNotes: 818,
    futureConstant: 7.5,
    packName: 'Arcaea',
  },
  {
    id: 73,
    jaTitle: 'Bookmaker (2D Version)',
    enTitle: 'Bookmaker (2D Version)',
    pastLevel: '4',
    pastNotes: 538,
    pastConstant: 4.5,
    presentLevel: '6',
    presentNotes: 728,
    presentConstant: 6.5,
    futureLevel: '8',
    futureNotes: 1124,
    futureConstant: 8.3,
    beyondLevel: '10',
    beyondConstant: 10.0,
    beyondNotes: 1287,
    packName: 'Arcaea',
  },
  {
    id: 74,
    jaTitle: '堕楽の園',
    enTitle: 'Illegal Paradise',
    pastLevel: '2',
    pastNotes: 535,
    pastConstant: 2,
    presentLevel: '7',
    presentNotes: 585,
    presentConstant: 7.5,
    futureLevel: '9',
    futureNotes: 1061,
    futureConstant: 9.5,
    packName: 'Arcaea',
  },
  {
    id: 75,
    jaTitle: 'Nhelv',
    enTitle: 'Nhelv',
    pastLevel: '3',
    pastNotes: 647,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 913,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1108,
    futureConstant: 9.9,
    packName: 'Arcaea',
  },
  {
    id: 76,
    jaTitle: '白道、多希望羊と信じありく。',
    enTitle: 'LunarOrbit -believe in the Espebranch road-',
    pastLevel: '3',
    pastNotes: 624,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 757,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 1058,
    futureConstant: 9.6,
    packName: 'Arcaea',
  },
  {
    id: 77,
    jaTitle: 'Purgatorium',
    enTitle: 'Purgatorium',
    pastLevel: '2',
    pastNotes: 609,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 641,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 983,
    futureConstant: 8.4,
    beyondLevel: '9',
    beyondNotes: 1051,
    beyondConstant: 9.6,
    packName: 'Arcaea',
  },
  {
    id: 78,
    jaTitle: 'Rugie',
    enTitle: 'Rugie',
    pastLevel: '3',
    pastNotes: 566,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 754,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 975,
    futureConstant: 9.2,
    packName: 'Arcaea',
  },
  {
    id: 79,
    jaTitle: 'ReviXy',
    enTitle: 'ReviXy',
    pastLevel: '3',
    pastNotes: 538,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 729,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 1047,
    futureConstant: 9,
    packName: 'Arcaea',
  },
  {
    id: 80,
    jaTitle: 'Grimheart',
    enTitle: 'Grimheart',
    pastLevel: '2',
    pastNotes: 728,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 699,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 959,
    futureConstant: 8.6,
    packName: 'Arcaea',
  },
  {
    id: 81,
    jaTitle: 'VECTOЯ',
    enTitle: 'VECTOЯ',
    pastLevel: '3',
    pastNotes: 675,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 1002,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 1299,
    futureConstant: 9.4,
    packName: 'Arcaea',
  },
  {
    id: 82,
    jaTitle: 'SUPERNOVA',
    enTitle: 'SUPERNOVA',
    pastLevel: '3',
    pastNotes: 664,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 564,
    presentConstant: 6,
    futureLevel: '9+',
    futureNotes: 1123,
    futureConstant: 9.7,
    packName: 'Arcaea',
  },
  {
    id: 83,
    jaTitle: 'Diode',
    enTitle: 'Diode',
    pastLevel: '2',
    pastNotes: 452,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 472,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 709,
    futureConstant: 8.1,
    packName: 'Arcaea',
  },
  {
    id: 84,
    jaTitle: 'FREEF4LL',
    enTitle: 'FREEF4LL',
    pastLevel: '4',
    pastNotes: 589,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 782,
    presentConstant: 7,
    futureLevel: '8',
    futureNotes: 1023,
    futureConstant: 8.6,
    beyondLevel: '9+',
    beyondNotes: 1336,
    beyondConstant: 9.8,
    packName: 'Arcaea',
  },
  {
    id: 85,
    jaTitle: 'Monochrome Princess',
    enTitle: 'Monochrome Princess',
    pastLevel: '4',
    pastNotes: 621,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 756,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 974,
    futureConstant: 9.7,
    packName: 'Arcaea',
  },
  {
    id: 86,
    jaTitle: 'Senkyou',
    enTitle: 'Senkyou',
    pastLevel: '3',
    pastNotes: 627,
    pastConstant: 3,
    presentLevel: '5',
    presentNotes: 722,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 964,
    futureConstant: 8.7,
    packName: 'Arcaea',
  },
  {
    id: 87,
    jaTitle: 'world.execute(me);',
    enTitle: 'world.execute(me);',
    pastLevel: '3',
    pastNotes: 452,
    pastConstant: 3.5,
    presentLevel: '5',
    presentNotes: 582,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 851,
    futureConstant: 8.0,
    packName: 'Arcaea',
  },
  {
    id: 88,
    jaTitle: 'Oblivia',
    enTitle: 'Oblivia',
    pastLevel: '3',
    pastNotes: 574,
    pastConstant: 3.5,
    presentLevel: '5',
    presentNotes: 517,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 956,
    futureConstant: 8.3,
    packName: 'Arcaea',
  },
  {
    id: 89,
    jaTitle: 'Vivid Theory',
    enTitle: 'Vivid Theory',
    pastLevel: '2',
    pastNotes: 447,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 658,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 885,
    futureConstant: 8.8,
    packName: 'Extend Archive',
  },
  {
    id: 90,
    jaTitle: '1F√',
    enTitle: '1F√',
    pastLevel: '2',
    pastNotes: 411,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 539,
    presentConstant: 6.5,
    futureLevel: '8',
    futureNotes: 758,
    futureConstant: 8.2,
    packName: 'Extend Archive',
  },
  {
    id: 91,
    jaTitle: 'Gekka (Short Version)',
    enTitle: 'Gekka (Short Version)',
    pastLevel: '4',
    pastNotes: 559,
    pastConstant: 4,
    presentLevel: '6',
    presentNotes: 628,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 817,
    futureConstant: 8.6,
    packName: 'Extend Archive',
  },
  {
    id: 92,
    jaTitle: 'Give Me a Nightmare',
    enTitle: 'Give Me a Nightmare',
    pastLevel: '3',
    pastNotes: 676,
    pastConstant: 3.5,
    presentLevel: '5',
    presentNotes: 817,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 948,
    futureConstant: 8.9,
    packName: 'Extend Archive',
  },
  {
    id: 93,
    jaTitle: 'Black Lotus',
    enTitle: 'Black Lotus',
    pastLevel: '3',
    pastNotes: 653,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 687,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 965,
    futureConstant: 9.7,
    packName: 'Extend Archive',
  },
  {
    id: 94,
    jaTitle: 'Faint Light (Arcaea Edit)',
    enTitle: 'Faint Light (Arcaea Edit)',
    pastLevel: '3',
    pastNotes: 587,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 711,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 809,
    futureConstant: 9.1,
    packName: 'Extend Archive',
  },
  {
    id: 95,
    jaTitle: 'CROSS†OVER',
    enTitle: 'CROSS†OVER',
    pastLevel: '4',
    pastNotes: 653,
    pastConstant: 4.0,
    presentLevel: '6',
    presentNotes: 810,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1094,
    futureConstant: 9.4,
    packName: 'Extend Archive',
  },
  {
    id: 96,
    jaTitle: 'Equilibrium',
    enTitle: 'Equilibrium',
    pastLevel: '3',
    pastNotes: 587,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 724,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 951,
    futureConstant: 9.4,
    packName: 'Black Fate',
  },
  {
    id: 97,
    jaTitle: 'Antagonism',
    enTitle: 'Antagonism',
    pastLevel: '4',
    pastNotes: 431,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 738,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1142,
    futureConstant: 9.9,
    packName: 'Black Fate',
  },
  {
    id: 98,
    jaTitle: 'Lost Desire',
    enTitle: 'Lost Desire',
    pastLevel: '4',
    pastNotes: 684,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 871,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1154,
    futureConstant: 9.8,
    packName: 'Black Fate',
  },
  {
    id: 99,
    jaTitle: 'Dantalion',
    enTitle: 'Dantalion',
    pastLevel: '5',
    pastNotes: 731,
    pastConstant: 5,
    presentLevel: '8',
    presentNotes: 843,
    presentConstant: 8.2,
    futureLevel: '10+',
    futureNotes: 1476,
    futureConstant: 10.9,
    packName: 'Black Fate',
  },
  {
    id: 100,
    jaTitle: '#1f1e33',
    enTitle: '#1f1e33',
    pastLevel: '5',
    pastNotes: 765,
    pastConstant: 5.5,
    presentLevel: '9',
    presentNotes: 1144,
    presentConstant: 9.2,
    futureLevel: '10+',
    futureNotes: 1576,
    futureConstant: 10.9,
    packName: 'Black Fate',
  },
  {
    id: 101,
    jaTitle: 'Tempestissimo',
    enTitle: 'Tempestissimo',
    pastLevel: '6',
    pastNotes: 919,
    pastConstant: 6.5,
    presentLevel: '9',
    presentNotes: 1034,
    presentConstant: 9.5,
    futureLevel: '10+',
    futureNotes: 1254,
    futureConstant: 10.7,
    beyondLevel: '11',
    beyondNotes: 1540,
    beyondConstant: 11.5,
    packName: 'Black Fate',
  },
  {
    id: 102,
    jaTitle: 'Arcahv',
    enTitle: 'Arcahv',
    pastLevel: '4',
    pastNotes: 818,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 884,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1065,
    futureConstant: 9.9,
    packName: 'Black Fate',
  },
  {
    id: 103,
    jaTitle: 'Particle Arts',
    enTitle: 'Particle Arts',
    pastLevel: '3',
    pastNotes: 529,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 637,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 925,
    futureConstant: 8.8,
    packName: 'Adverse Prelude',
  },
  {
    id: 104,
    jaTitle: 'Vindication',
    enTitle: 'Vindication',
    pastLevel: '4',
    pastNotes: 721,
    pastConstant: 4,
    presentLevel: '6',
    presentNotes: 899,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1075,
    futureConstant: 9.6,
    packName: 'Adverse Prelude',
  },
  {
    id: 105,
    jaTitle: 'Heavensdoor',
    enTitle: 'Heavensdoor',
    pastLevel: '4',
    pastNotes: 766,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 869,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1101,
    futureConstant: 9.9,
    beyondLevel: '10+',
    beyondNotes: 1534,
    beyondConstant: 10.8,
    packName: 'Adverse Prelude',
  },
  {
    id: 106,
    jaTitle: 'Ringed Genesis',
    enTitle: 'Ringed Genesis',
    pastLevel: '5',
    pastNotes: 672,
    pastConstant: 5.5,
    presentLevel: '8',
    presentNotes: 860,
    presentConstant: 8.5,
    futureLevel: '10+',
    futureNotes: 1146,
    futureConstant: 10.8,
    packName: 'Adverse Prelude',
  },
  {
    id: 107,
    jaTitle: 'BLRINK',
    enTitle: 'BLRINK',
    pastLevel: '3',
    pastNotes: 400,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 683,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1015,
    futureConstant: 9.7,
    packName: 'Adverse Prelude',
  },
  {
    id: 108,
    jaTitle: '九番目の迷路',
    enTitle: 'Maze No.9',
    pastLevel: '3',
    pastNotes: 494,
    pastConstant: 3,
    presentLevel: '3',
    presentNotes: 445,
    presentConstant: 3.5,
    futureLevel: '8',
    futureNotes: 775,
    futureConstant: 8.9,
    packName: 'Luminous Sky',
  },
  {
    id: 109,
    jaTitle: 'The Message',
    enTitle: 'The Message',
    pastLevel: '3',
    pastNotes: 536,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 630,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 992,
    futureConstant: 9.7,
    packName: 'Luminous Sky',
  },
  {
    id: 110,
    jaTitle: 'Sulfur',
    enTitle: 'Sulfur',
    pastLevel: '4',
    pastNotes: 458,
    pastConstant: 4,
    presentLevel: '6',
    presentNotes: 608,
    presentConstant: 6,
    futureLevel: '9+',
    futureNotes: 1045,
    futureConstant: 9.7,
    packName: 'Luminous Sky',
  },
  {
    id: 111,
    jaTitle: 'Halcyon',
    enTitle: 'Halcyon',
    pastLevel: '5',
    pastNotes: 662,
    pastConstant: 5.5,
    presentLevel: '8',
    presentNotes: 943,
    presentConstant: 8.2,
    futureLevel: '10+',
    futureNotes: 1227,
    futureConstant: 10.7,
    packName: 'Luminous Sky',
  },
  {
    id: 112,
    jaTitle: 'Ether Strike',
    enTitle: 'Ether Strike',
    pastLevel: '5',
    pastNotes: 659,
    pastConstant: 5.5,
    presentLevel: '8',
    presentNotes: 837,
    presentConstant: 8.2,
    futureLevel: '10',
    futureNotes: 1170,
    futureConstant: 10.3,
    packName: 'Luminous Sky',
  },
  {
    id: 113,
    jaTitle: 'Fracture Ray',
    enTitle: 'Fracture Ray',
    pastLevel: '6',
    pastNotes: 983,
    pastConstant: 6,
    presentLevel: '9',
    presentNotes: 1343,
    presentConstant: 9.5,
    futureLevel: '11',
    futureNotes: 1279,
    futureConstant: 11.3,
    packName: 'Luminous Sky',
  },
  {
    id: 114,
    jaTitle: 'Iconoclast',
    enTitle: 'Iconoclast',
    pastLevel: '4',
    pastNotes: 443,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 593,
    presentConstant: 7.0,
    futureLevel: '9',
    futureNotes: 795,
    futureConstant: 9.1,
    packName: 'Vicious Labyrinth',
  },
  {
    id: 115,
    jaTitle: 'SOUNDWiTCH',
    enTitle: 'SOUNDWiTCH',
    pastLevel: '3',
    pastNotes: 315,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 488,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 785,
    futureConstant: 9.9,
    packName: 'Vicious Labyrinth',
  },
  {
    id: 116,
    jaTitle: '妖艶魔女 -trappola bewitching-',
    enTitle: 'trappola bewitching',
    pastLevel: '3',
    pastNotes: 415,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 541,
    presentConstant: 6,
    futureLevel: '10',
    futureNotes: 1044,
    futureConstant: 10,
    beyondLevel: '10',
    beyondNotes: 1086,
    beyondConstant: 10.5,
    packName: 'Vicious Labyrinth',
  },
  {
    id: 117,
    jaTitle: 'conflict',
    enTitle: 'conflict',
    pastLevel: '4',
    pastNotes: 520,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 731,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1056,
    futureConstant: 10.2,
    packName: 'Vicious Labyrinth',
  },
  {
    id: 118,
    jaTitle: 'Axium Crisis',
    enTitle: 'Axium Crisis',
    pastLevel: '5',
    pastNotes: 685,
    pastConstant: 5.5,
    presentLevel: '8',
    presentNotes: 1065,
    presentConstant: 8.3,
    futureLevel: '10+',
    futureNotes: 1094,
    futureConstant: 10.7,
    packName: 'Vicious Labyrinth',
  },
  {
    id: 119,
    jaTitle: 'Grievous Lady',
    enTitle: 'Grievous Lady',
    pastLevel: '6',
    pastNotes: 956,
    pastConstant: 6.5,
    presentLevel: '9',
    presentNotes: 1194,
    presentConstant: 9.3,
    futureLevel: '11',
    futureNotes: 1450,
    futureConstant: 11.3,
    packName: 'Vicious Labyrinth',
  },
  {
    id: 120,
    jaTitle: 'cry of viyella',
    enTitle: 'cry of viyella',
    pastLevel: '3',
    pastNotes: 414,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 492,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 791,
    futureConstant: 8.5,
    packName: 'Eternal Core',
  },
  {
    id: 121,
    jaTitle: "I've heard it said",
    enTitle: "I've heard it said",
    pastLevel: '3',
    pastNotes: 510,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 664,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 864,
    futureConstant: 8.1,
    packName: 'Eternal Core',
  },
  {
    id: 122,
    jaTitle: 'memoryfactory.lzh',
    enTitle: 'memoryfactory.lzh',
    pastLevel: '2',
    pastNotes: 308,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 448,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 672,
    futureConstant: 8.9,
    packName: 'Eternal Core',
  },
  {
    id: 123,
    jaTitle: 'Relentless',
    enTitle: 'Relentless',
    pastLevel: '4',
    pastNotes: 607,
    pastConstant: 4.5,
    presentLevel: '6',
    presentNotes: 722,
    presentConstant: 6.5,
    futureLevel: '8',
    futureNotes: 1015,
    futureConstant: 8,
    packName: 'Eternal Core',
  },
  {
    id: 124,
    jaTitle: 'Lumia',
    enTitle: 'Lumia',
    pastLevel: '2',
    pastNotes: 469,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 438,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 961,
    futureConstant: 8.4,
    beyondLevel: '9',
    beyondNotes: 814,
    beyondConstant: 9.5,
    packName: 'Eternal Core',
  },
  {
    id: 125,
    jaTitle: 'Essence of Twilight',
    enTitle: 'Essence of Twilight',
    pastLevel: '4',
    pastNotes: 556,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 767,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 1204,
    futureConstant: 9.1,
    packName: 'Eternal Core',
  },
  {
    id: 126,
    jaTitle: 'PRAGMATISM',
    enTitle: 'PRAGMATISM',
    pastLevel: '4',
    pastNotes: 476,
    pastConstant: 4.5,
    presentLevel: '8',
    presentNotes: 855,
    presentConstant: 8.6,
    futureLevel: '10',
    futureNotes: 942,
    futureConstant: 10.1,
    packName: 'Eternal Core',
  },
  {
    id: 126,
    jaTitle: 'PRAGMATISM -RESURRECTION-',
    enTitle: 'PRAGMATISM -RESURRECTION-',
    beyondLevel: '11',
    beyondNotes: 1502,
    beyondConstant: 11.0,
    packName: 'Eternal Core',
  },
  {
    id: 127,
    jaTitle: 'Sheriruth',
    enTitle: 'Sheriruth',
    pastLevel: '5',
    pastNotes: 611,
    pastConstant: 5.5,
    presentLevel: '7',
    presentNotes: 832,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1151,
    futureConstant: 10.1,
    packName: 'Eternal Core',
  },
  {
    id: 128,
    jaTitle: '虚空の夢',
    enTitle: 'Sollitary Dream',
    pastLevel: '4',
    pastNotes: 712,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 854,
    presentConstant: 7.0,
    futureLevel: '8',
    futureNotes: 972,
    futureConstant: 8.8,
    packName: 'Eternal Core',
  },
  {
    id: 129,
    jaTitle: 'Chelsea',
    enTitle: 'Chelsea',
    pastLevel: '3',
    pastNotes: 388,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 503,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 650,
    futureConstant: 8.5,
    packName: 'Sunset Radiance',
  },
  {
    id: 130,
    jaTitle: 'AI[UE]OON',
    enTitle: 'AI[UE]OON',
    pastLevel: '3',
    pastNotes: 436,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 623,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 989,
    futureConstant: 9.4,
    packName: 'Sunset Radiance',
  },
  {
    id: 131,
    jaTitle: '迷える音色は恋の唄',
    enTitle: 'A wandering Melody of Love',
    pastLevel: '3',
    pastNotes: 422,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 670,
    presentConstant: 7.5,
    futureLevel: '9',
    futureNotes: 931,
    futureConstant: 9.6,
    packName: 'Sunset Radiance',
  },
  {
    id: 132,
    jaTitle: 'Tie me down gently',
    enTitle: 'Tie me down gently',
    pastLevel: '3',
    pastNotes: 581,
    pastConstant: 3,
    presentLevel: '5',
    presentNotes: 535,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 724,
    futureConstant: 8.3,
    packName: 'Sunset Radiance',
  },
  {
    id: 133,
    jaTitle: 'Valhalla:0',
    enTitle: 'Valhalla:0',
    pastLevel: '4',
    pastNotes: 624,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 893,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1173,
    futureConstant: 10.4,
    packName: 'Sunset Radiance',
  },
  {
    id: 134,
    jaTitle: 'Antithese',
    enTitle: 'Antithese',
    pastLevel: '2',
    pastNotes: 845,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 826,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 877,
    futureConstant: 8.8,
    beyondLevel: '9',
    beyondConstant: 9.5,
    beyondNotes: 968,
    packName: 'Absolute Reason',
  },
  {
    id: 135,
    jaTitle: 'Black Territory',
    enTitle: 'Black Territory',
    pastLevel: '3',
    pastNotes: 627,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 785,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1195,
    futureConstant: 9.7,
    packName: 'Absolute Reason',
  },
  {
    id: 136,
    jaTitle: 'Corruption',
    enTitle: 'Corruption',
    pastLevel: '3',
    pastNotes: 664,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 847,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1293,
    futureConstant: 9.8,
    packName: 'Absolute Reason',
  },
  {
    id: 137,
    jaTitle: 'Vicious Heroism',
    enTitle: 'Vicious Heroism',
    pastLevel: '4',
    pastNotes: 430,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 756,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1150,
    futureConstant: 10,
    packName: 'Absolute Reason',
  },
  {
    id: 138,
    jaTitle: 'Cyaegha',
    enTitle: 'Cyaegha',
    pastLevel: '5',
    pastNotes: 760,
    pastConstant: 5.5,
    presentLevel: '8',
    presentNotes: 984,
    presentConstant: 8.1,
    futureLevel: '10+',
    futureNotes: 1368,
    futureConstant: 10.7,
    packName: 'Absolute Reason',
  },
  {
    id: 139,
    jaTitle: 'next to you',
    enTitle: 'next to you',
    pastLevel: '4',
    pastNotes: 454,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 583,
    presentConstant: 7.0,
    futureLevel: '8',
    futureNotes: 824,
    futureConstant: 8.8,
    beyondLevel: '9',
    beyondNotes: 954,
    beyondConstant: 9.6,
    packName: 'Binary Enfold',
  },
  {
    id: 140,
    jaTitle: 'Silent Rush',
    enTitle: 'Silent Rush',
    pastLevel: '2',
    pastNotes: 416,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 674,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 941,
    futureConstant: 8.6,
    packName: 'Binary Enfold',
  },
  {
    id: 141,
    jaTitle: 'Strongholds',
    enTitle: 'Strongholds',
    pastLevel: '2',
    pastNotes: 570,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 778,
    presentConstant: 5,
    futureLevel: '9',
    futureNotes: 922,
    futureConstant: 9.2,
    packName: 'Binary Enfold',
  },
  {
    id: 142,
    jaTitle: 'Memory Forest',
    enTitle: 'Memory Forest',
    pastLevel: '3',
    pastNotes: 639,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 726,
    presentConstant: 6,
    futureLevel: '9+',
    futureNotes: 978,
    futureConstant: 9.8,
    packName: 'Binary Enfold',
  },
  {
    id: 143,
    jaTitle: 'Singularity',
    enTitle: 'Singularity',
    pastLevel: '4',
    pastNotes: 534,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 678,
    presentConstant: 7.5,
    futureLevel: '10+',
    futureNotes: 1105,
    futureConstant: 10.7,
    packName: 'Binary Enfold',
  },
  {
    id: 143,
    jaTitle: 'Singularity VVVIP',
    enTitle: 'Singularity VVVIP',
    beyondLevel: '10',
    beyondNotes: 1114,
    beyondConstant: 10.4,
    packName: 'Binary Enfold',
  },
  {
    id: 144,
    jaTitle: 'Blossoms',
    enTitle: 'Blossoms',
    pastLevel: '1',
    pastNotes: 275,
    pastConstant: 1,
    presentLevel: '4',
    presentNotes: 383,
    presentConstant: 4,
    futureLevel: '7',
    futureNotes: 655,
    futureConstant: 7,
    packName: 'Ambivalent Vision',
  },
  {
    id: 145,
    jaTitle: 'vsキミ戦争',
    enTitle: 'Romance Wars',
    pastLevel: '1',
    pastNotes: 423,
    pastConstant: 1,
    presentLevel: '4',
    presentNotes: 378,
    presentConstant: 4,
    futureLevel: '7',
    futureNotes: 641,
    futureConstant: 7.5,
    packName: 'Ambivalent Vision',
  },
  {
    id: 146,
    jaTitle: 'Moonheart',
    enTitle: 'Moonheart',
    pastLevel: '2',
    pastNotes: 449,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 566,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 947,
    futureConstant: 8.4,
    beyondLevel: '9+',
    beyondNotes: 1139,
    beyondConstant: 9.7,
    packName: 'Ambivalent Vision',
  },
  {
    id: 147,
    jaTitle: 'Genesis',
    enTitle: 'Genesis',
    pastLevel: '2',
    pastNotes: 275,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 399,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 713,
    futureConstant: 8.2,
    packName: 'Ambivalent Vision',
  },
  {
    id: 148,
    jaTitle: 'Lethaeus',
    enTitle: 'Lethaeus',
    pastLevel: '3',
    pastNotes: 480,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 717,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 900,
    futureConstant: 9.7,
    packName: 'Ambivalent Vision',
  },
  {
    id: 149,
    jaTitle: 'corps-sans-organes',
    enTitle: 'corps-sans-organes',
    pastLevel: '4',
    pastNotes: 438,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 615,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1077,
    futureConstant: 10.6,
    packName: 'Ambivalent Vision',
  },
  {
    id: 150,
    jaTitle: 'Paradise',
    enTitle: 'Paradise',
    pastLevel: '1',
    pastNotes: 253,
    pastConstant: 1,
    presentLevel: '4',
    presentNotes: 349,
    presentConstant: 4,
    futureLevel: '7',
    futureNotes: 729,
    futureConstant: 7.5,
    packName: 'Crimson Solace',
  },
  {
    id: 151,
    jaTitle: 'Flashback',
    enTitle: 'Flashback',
    pastLevel: '2',
    pastNotes: 356,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 488,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 856,
    futureConstant: 8.8,
    packName: 'Crimson Solace',
  },
  {
    id: 152,
    jaTitle: 'フライブルクとエンドロウル',
    enTitle: 'Flyburg and Endroll',
    pastLevel: '3',
    pastNotes: 413,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 650,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 930,
    futureConstant: 9.1,
    packName: 'Crimson Solace',
  },
  {
    id: 153,
    jaTitle: 'Party Vinyl',
    enTitle: 'Party Vinyl',
    pastLevel: '4',
    pastNotes: 337,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 543,
    presentConstant: 7.5,
    futureLevel: '9',
    futureNotes: 800,
    futureConstant: 9.4,
    beyondLevel: '10',
    beyondNotes: 946,
    beyondConstant: 10.1,
    packName: 'Crimson Solace',
  },
  {
    id: 154,
    jaTitle: 'Nirv lucE',
    enTitle: 'Nirv lucE',
    pastLevel: '2',
    pastNotes: 297,
    pastConstant: 2.5,
    presentLevel: '7',
    presentNotes: 547,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 980,
    futureConstant: 10.3,
    packName: 'Crimson Solace',
  },
  {
    id: 155,
    jaTitle: 'GLORY：ROAD',
    enTitle: 'GLORY：ROAD',
    pastLevel: '4',
    pastNotes: 868,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 999,
    presentConstant: 7.0,
    futureLevel: '10',
    futureNotes: 1479,
    futureConstant: 10.6,
    packName: 'Crimson Solace',
  },
  {
    id: 156,
    jaTitle: 'Garakuta Doll Play',
    enTitle: 'Garakuta Doll Play',
    pastLevel: '4',
    pastNotes: 444,
    pastConstant: 4.5,
    presentLevel: '6',
    presentNotes: 572,
    presentConstant: 6.5,
    futureLevel: '10',
    futureNotes: 1035,
    futureConstant: 10.4,
    packName: 'CHUNITHM Collaboration',
  },
  {
    id: 157,
    jaTitle: '怒槌',
    enTitle: 'Ikazuchi',
    pastLevel: '3',
    pastNotes: 656,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 976,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1347,
    futureConstant: 10.4,
    packName: 'CHUNITHM Collaboration',
  },
  {
    id: 158,
    jaTitle: 'World Vanquisher',
    enTitle: 'World Vanquisher',
    pastLevel: '2',
    pastNotes: 529,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 759,
    presentConstant: 5.5,
    futureLevel: '10+',
    futureNotes: 1452,
    futureConstant: 10.8,
    packName: 'CHUNITHM Collaboration',
  },
  {
    id: 159,
    jaTitle: 'MERLIN',
    enTitle: 'MERLIN',
    pastLevel: '3',
    pastNotes: 315,
    pastConstant: 3,
    presentLevel: '5',
    presentNotes: 428,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 712,
    futureConstant: 8.9,
    packName: 'Groove Coaster Collaboration',
  },
  {
    id: 160,
    jaTitle: 'DX超性能フルメタル少女',
    enTitle: 'DX Chouseinou Full Metal Shojo',
    pastLevel: '3',
    pastNotes: 327,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 556,
    presentConstant: 6,
    futureLevel: '9+',
    futureNotes: 808,
    futureConstant: 9.8,
    packName: 'Groove Coaster Collaboration',
  },
  {
    id: 161,
    jaTitle: 'OMAKENO Stroke',
    enTitle: 'OMAKENO Stroke',
    pastLevel: '3',
    pastNotes: 502,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 616,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 869,
    futureConstant: 9.5,
    beyondLevel: '10',
    beyondNotes: 931,
    beyondConstant: 10.2,
    packName: 'Groove Coaster Collaboration',
  },
  {
    id: 162,
    jaTitle: 'Scarlet Lance',
    enTitle: 'Scarlet Lance',
    pastLevel: '4',
    pastNotes: 517,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 644,
    presentConstant: 7,
    futureLevel: '10',
    futureNotes: 1130,
    futureConstant: 10.1,
    packName: 'Groove Coaster Collaboration',
  },
  {
    id: 163,
    jaTitle: 'ouroboros -twin stroke of the end-',
    enTitle: 'ouroboros -twin stroke of the end-',
    pastLevel: '4',
    pastNotes: 575,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 832,
    presentConstant: 7.0,
    futureLevel: '10+',
    futureNotes: 1369,
    futureConstant: 10.7,
    packName: 'Groove Coaster Collaboration',
  },
  {
    id: 164,
    jaTitle: 'Got hive of Ra',
    enTitle: 'Got hive of Ra',
    pastLevel: '3',
    pastNotes: 322,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 509,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 794,
    futureConstant: 9.8,
    packName: 'Groove Coaster Collaboration',
  },
  {
    id: 165,
    jaTitle: '光',
    enTitle: 'Hikari',
    pastLevel: '2',
    pastNotes: 237,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 450,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 684,
    futureConstant: 8.1,
    packName: 'Tone Sphere Collaboration',
  },
  {
    id: 166,
    jaTitle: 'Hall of Mirrors',
    enTitle: 'Hall of Mirrors',
    pastLevel: '3',
    pastNotes: 535,
    pastConstant: 3,
    presentLevel: '5',
    presentNotes: 553,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 898,
    futureConstant: 8.2,
    packName: 'Tone Sphere Collaboration',
  },
  {
    id: 167,
    jaTitle: 'STAGER (ALL STAGE CLEAR)',
    enTitle: 'STAGER (ALL STAGE CLEAR)',
    pastLevel: '3',
    pastNotes: 453,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 559,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1004,
    futureConstant: 9.5,
    packName: 'Tone Sphere Collaboration',
  },
  {
    id: 168,
    jaTitle: 'Linear Accelerator',
    enTitle: 'Linear Accelerator',
    pastLevel: '2',
    pastNotes: 438,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 488,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 905,
    futureConstant: 9.8,
    packName: 'Tone Sphere Collaboration',
  },
  {
    id: 169,
    jaTitle: 'Tiferet',
    enTitle: 'Tiferet',
    pastLevel: '4',
    pastNotes: 450,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 720,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1086,
    futureConstant: 10.4,
    packName: 'Tone Sphere Collaboration',
  },
  {
    id: 170,
    jaTitle: 'Dream goes on',
    enTitle: 'Dream goes on',
    pastLevel: '1',
    pastNotes: 532,
    pastConstant: 1.5,
    presentLevel: '5',
    presentNotes: 751,
    presentConstant: 5,
    futureLevel: '7',
    futureNotes: 719,
    futureConstant: 7.5,
    packName: 'Lanota Collaboration',
  },
  {
    id: 171,
    jaTitle: 'Journey',
    enTitle: 'Journey',
    pastLevel: '3',
    pastNotes: 551,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 778,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 997,
    futureConstant: 8.8,
    packName: 'Lanota Collaboration',
  },
  {
    id: 172,
    jaTitle: 'Specta',
    enTitle: 'Specta',
    pastLevel: '3',
    pastNotes: 390,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 706,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1096,
    futureConstant: 9.5,
    packName: 'Lanota Collaboration',
  },
  {
    id: 173,
    jaTitle: 'Quon(Feryquitous)',
    enTitle: 'Quon(Feryquitous)',
    pastLevel: '4',
    pastNotes: 547,
    pastConstant: 4,
    presentLevel: '6',
    presentNotes: 718,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 991,
    futureConstant: 9.6,
    packName: 'Lanota Collaboration',
  },
  {
    id: 174,
    jaTitle: 'cyanine',
    enTitle: 'cyanine',
    pastLevel: '4',
    pastNotes: 661,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 947,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1171,
    futureConstant: 10.6,
    packName: 'Lanota Collaboration',
  },
  {
    id: 175,
    jaTitle: 'Moonlight of Sand Castle',
    enTitle: 'Moonlight of Sand Castle',
    pastLevel: '1',
    pastNotes: 418,
    pastConstant: 1.5,
    presentLevel: '5',
    presentNotes: 394,
    presentConstant: 5,
    futureLevel: '7',
    futureNotes: 645,
    futureConstant: 7.5,
    packName: 'Dynamix Collaboration',
  },
  {
    id: 176,
    jaTitle: 'REconstruction',
    enTitle: 'REconstruction',
    pastLevel: '2',
    pastNotes: 347,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 469,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 825,
    futureConstant: 8.4,
    packName: 'Dynamix Collaboration',
  },
  {
    id: 177,
    jaTitle: "Evoltex (poppi'n mix)",
    enTitle: "Evoltex (poppi'n mix)",
    pastLevel: '2',
    pastNotes: 297,
    pastConstant: 2,
    presentLevel: '7',
    presentNotes: 627,
    presentConstant: 7.0,
    futureLevel: '8',
    futureNotes: 775,
    futureConstant: 8.9,
    packName: 'Dynamix Collaboration',
  },
  {
    id: 178,
    jaTitle: 'Oracle',
    enTitle: 'Oracle',
    pastLevel: '3',
    pastNotes: 425,
    pastConstant: 3,
    presentLevel: '5',
    presentNotes: 508,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 963,
    futureConstant: 9.3,
    packName: 'Dynamix Collaboration',
  },
  {
    id: 179,
    jaTitle: 'αterlβus',
    enTitle: 'αterlβus',
    pastLevel: '4',
    pastNotes: 505,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 668,
    presentConstant: 7.0,
    futureLevel: '10',
    futureNotes: 1030,
    futureConstant: 10.2,
    packName: 'Dynamix Collaboration',
  },
  {
    id: 180,
    jaTitle: 'Xeraphinite',
    enTitle: 'Xeraphinite',
    pastLevel: '3',
    pastNotes: 383,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 643,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1048,
    futureConstant: 9.8,
    packName: 'Memory Archive',
  },
  {
    id: 181,
    jaTitle: 'Lapis',
    enTitle: 'Lapis',
    pastLevel: '2',
    pastNotes: 391,
    pastConstant: 2,
    presentLevel: '6',
    presentNotes: 520,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 920,
    futureConstant: 9.4,
    packName: 'Extend Archive',
  },
  {
    id: 182,
    jaTitle: 'Xanatos',
    enTitle: 'Xanatos',
    pastLevel: '4',
    pastNotes: 735,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 938,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1232,
    futureConstant: 10,
    packName: 'Memory Archive',
  },
  {
    id: 183,
    jaTitle: 'Alice à la mode',
    enTitle: 'Alice à la mode',
    pastLevel: '2',
    pastNotes: 436,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 618,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 872,
    futureConstant: 9.2,
    packName: 'Ephemeral Page',
  },
  {
    id: 184,
    jaTitle: 'Eccentric Tale',
    enTitle: 'Eccentric Tale',
    pastLevel: '2',
    pastNotes: 313,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 483,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 732,
    futureConstant: 8.4,
    packName: 'Ephemeral Page',
  },
  {
    id: 185,
    jaTitle: "Alice's Suitcase",
    enTitle: "Alice's Suitcase",
    pastLevel: '3',
    pastNotes: 644,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 660,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 999,
    futureConstant: 9.1,
    packName: 'Ephemeral Page',
  },
  {
    id: 186,
    jaTitle: 'Jump',
    enTitle: 'Jump',
    pastLevel: '4',
    pastNotes: 531,
    pastConstant: 4,
    presentLevel: '6',
    presentNotes: 622,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 841,
    futureConstant: 9,
    packName: 'Ephemeral Page',
  },
  {
    id: 187,
    jaTitle: 'Felis',
    enTitle: 'Felis',
    pastLevel: '4',
    pastNotes: 463,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 624,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1153,
    futureConstant: 10.4,
    packName: 'Ephemeral Page',
  },
  {
    id: 188,
    jaTitle: 'Purple Verse',
    enTitle: 'Purple Verse',
    pastLevel: '4',
    pastNotes: 558,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 664,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 1023,
    futureConstant: 9.6,
    packName: 'Extend Archive',
  },
  {
    id: 189,
    jaTitle: 'Beside You',
    enTitle: 'Beside You',
    pastLevel: '2',
    pastNotes: 526,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 634,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 703,
    futureConstant: 8.7,
    packName: 'Ephemeral Page',
  },
  {
    id: 190,
    jaTitle: "Heart Jackin'",
    enTitle: "Heart Jackin'",
    pastLevel: '3',
    pastNotes: 506,
    pastConstant: 3,
    presentLevel: '5',
    presentNotes: 543,
    presentConstant: 5.5,
    futureLevel: '9+',
    futureNotes: 1112,
    futureConstant: 9.7,
    packName: 'Ephemeral Page',
  },
  {
    id: 191,
    jaTitle: 'アリス・リデルに捧ぐ',
    enTitle: 'To: Alice Liddell',
    pastLevel: '4',
    pastNotes: 535,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 674,
    presentConstant: 7,
    futureLevel: '10',
    futureNotes: 998,
    futureConstant: 10.3,
    packName: 'Ephemeral Page',
  },
  {
    id: 192,
    jaTitle: 'Lazy Addiction',
    enTitle: 'Lazy Addiction',
    pastLevel: '2',
    pastNotes: 462,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 647,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 1031,
    futureConstant: 9.6,
    packName: 'O.N.G.E.K.I. Collaboration',
  },
  {
    id: 193,
    jaTitle: 'Dazzle hop',
    enTitle: 'Dazzle hop',
    pastLevel: '3',
    pastNotes: 561,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 571,
    presentConstant: 6,
    futureLevel: '9+',
    futureNotes: 1022,
    futureConstant: 9.7,
    packName: 'O.N.G.E.K.I. Collaboration',
  },
  {
    id: 194,
    jaTitle: "Viyella's Tears",
    enTitle: "Viyella's Tears",
    pastLevel: '4',
    pastNotes: 716,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 942,
    presentConstant: 7,
    futureLevel: '10',
    futureNotes: 1403,
    futureConstant: 10.3,
    packName: 'O.N.G.E.K.I. Collaboration',
  },
  {
    id: 195,
    jaTitle: 'ω4',
    enTitle: 'ω4',
    pastLevel: '3',
    pastNotes: 816,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 943,
    presentConstant: 7.5,
    futureLevel: '10+',
    futureNotes: 1393,
    futureConstant: 10.8,
    packName: 'O.N.G.E.K.I. Collaboration',
  },
  {
    id: 196,
    jaTitle: '四月の雨',
    enTitle: 'April showers',
    pastLevel: '2',
    pastNotes: 363,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 544,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 697,
    futureConstant: 8.3,
    packName: 'maimai Collaboration',
  },
  {
    id: 197,
    jaTitle: '7thSense',
    enTitle: '7thSense',
    pastLevel: '3',
    pastNotes: 360,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 739,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 925,
    futureConstant: 9.9,
    packName: 'maimai Collaboration',
  },
  {
    id: 198,
    jaTitle: 'Oshama Scramble!',
    enTitle: 'Oshama Scramble!',
    pastLevel: '3',
    pastNotes: 508,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 568,
    presentConstant: 6.5,
    futureLevel: '10',
    futureNotes: 1073,
    futureConstant: 10,
    packName: 'maimai Collaboration',
  },
  {
    id: 199,
    jaTitle: 'AMAZING MIGHTYYYY!!!!',
    enTitle: 'AMAZING MIGHTYYYY!!!!',
    pastLevel: '4',
    pastNotes: 624,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 776,
    presentConstant: 7.5,
    futureLevel: '10+',
    futureNotes: 1249,
    futureConstant: 10.7,
    packName: 'maimai Collaboration',
  },
  {
    id: 200,
    jaTitle: 'Climax',
    enTitle: 'Climax',
    pastLevel: '4',
    pastNotes: 773,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 988,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1367,
    futureConstant: 10.4,
    packName: 'CHUNITHM Collaboration',
  },
  {
    id: 201,
    jaTitle: '業 -善なる神とこの世の悪について-',
    enTitle: "Misdeed -la bonté de Dieu et l'origine du mal-",
    pastLevel: '5',
    pastNotes: 814,
    pastConstant: 5.5,
    presentLevel: '8',
    presentNotes: 1070,
    presentConstant: 8.5,
    futureLevel: '10+',
    futureNotes: 1522,
    futureConstant: 10.9,
    packName: 'CHUNITHM Collaboration',
  },
  {
    id: 202,
    jaTitle: 'Last Celebration',
    enTitle: 'Last Celebration',
    pastLevel: '3',
    pastNotes: 969,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 994,
    presentConstant: 6.5,
    futureLevel: '10',
    futureNotes: 1475,
    futureConstant: 10.5,
    packName: 'CHUNITHM Collaboration',
  },
  {
    id: 203,
    jaTitle: 'AttraqtiA',
    enTitle: 'AttraqtiA',
    pastLevel: '4',
    pastNotes: 732,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 1002,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1433,
    futureConstant: 10.6,
    packName: 'Memory Archive',
  },
  {
    id: 204,
    jaTitle: 'Glow',
    enTitle: 'Glow',
    pastLevel: '4',
    pastNotes: 504,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 666,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 916,
    futureConstant: 9.3,
    packName: 'Extend Archive',
  },
  {
    id: 205,
    jaTitle: 'enchanted love',
    enTitle: 'enchanted love',
    pastLevel: '2',
    pastNotes: 436,
    pastConstant: 2,
    presentLevel: '4',
    presentNotes: 617,
    presentConstant: 4.5,
    futureLevel: '8',
    futureNotes: 759,
    futureConstant: 8.4,
    packName: 'Extend Archive',
  },
  {
    id: 206,
    jaTitle: '竹',
    enTitle: 'Bamboo',
    pastLevel: '3',
    pastNotes: 499,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 631,
    presentConstant: 6.5,
    futureLevel: '10',
    futureNotes: 1050,
    futureConstant: 10.0,
    packName: 'Extend Archive',
  },
  {
    id: 207,
    jaTitle: 'GIMME DA BLOOD',
    enTitle: 'GIMME DA BLOOD',
    pastLevel: '3',
    pastNotes: 582,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 737,
    presentConstant: 7,
    futureLevel: '10',
    futureNotes: 1093,
    futureConstant: 10.4,
    packName: 'Memory Archive',
  },
  {
    id: 208,
    jaTitle: 'ベースラインやってる？w',
    enTitle: 'Can I Friend You On Bassbook? Lol',
    pastLevel: '3',
    pastNotes: 415,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 548,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 861,
    futureConstant: 9.3,
    packName: 'Memory Archive',
  },
  {
    id: 209,
    jaTitle: 'Life is PIANO',
    enTitle: 'Life is PIANO',
    pastLevel: '3',
    pastNotes: 398,
    pastConstant: 3.5,
    presentLevel: '5',
    presentNotes: 437,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 674,
    futureConstant: 9.1,
    packName: 'Extend Archive',
  },
  {
    id: 210,
    jaTitle: 'Paper Witch',
    enTitle: 'Paper Witch',
    pastLevel: '2',
    pastNotes: 348,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 487,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 793,
    futureConstant: 8.7,
    packName: 'Esoteric Order',
  },
  {
    id: 211,
    jaTitle: 'Far Away Light',
    enTitle: 'Far Away Light',
    pastLevel: '4',
    pastNotes: 656,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 769,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1322,
    futureConstant: 9.8,
    packName: 'Esoteric Order',
  },
  {
    id: 212,
    jaTitle: 'Crystal Gravity',
    enTitle: 'Crystal Gravity',
    pastLevel: '3',
    pastNotes: 571,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 653,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 872,
    futureConstant: 9.4,
    packName: 'Esoteric Order',
  },
  {
    id: 213,
    jaTitle: 'Löschen',
    enTitle: 'Löschen',
    pastLevel: '3',
    pastNotes: 642,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 850,
    presentConstant: 7,
    futureLevel: '10',
    futureNotes: 1235,
    futureConstant: 10.2,
    packName: 'Esoteric Order',
  },
  {
    id: 214,
    jaTitle: 'Aegleseeker',
    enTitle: 'Aegleseeker',
    pastLevel: '5',
    pastNotes: 973,
    pastConstant: 5.5,
    presentLevel: '9',
    presentNotes: 1235,
    presentConstant: 9.1,
    futureLevel: '11',
    futureNotes: 1568,
    futureConstant: 11.1,
    packName: 'Esoteric Order',
  },
  {
    id: 215,
    jaTitle: 'Coastal Highway',
    enTitle: 'Coastal Highway',
    pastLevel: '3',
    pastNotes: 355,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 479,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 732,
    futureConstant: 8.8,
    packName: 'Esoteric Order',
  },
  {
    id: 216,
    jaTitle: 'ΟΔΥΣΣΕΙΑ',
    enTitle: 'ΟΔΥΣΣΕΙΑ',
    pastLevel: '4',
    pastNotes: 717,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 909,
    presentConstant: 7.5,
    futureLevel: '9',
    futureNotes: 1092,
    futureConstant: 9.5,
    packName: 'Esoteric Order',
  },
  {
    id: 217,
    jaTitle: 'Overwhelm',
    enTitle: 'Overwhelm',
    pastLevel: '5',
    pastNotes: 854,
    pastConstant: 5,
    presentLevel: '8',
    presentNotes: 1038,
    presentConstant: 8.5,
    futureLevel: '10',
    futureNotes: 1251,
    futureConstant: 10.6,
    packName: 'Esoteric Order',
  },
  {
    id: 218,
    jaTitle: 'Vandalism',
    enTitle: 'Vandalism',
    pastLevel: '3',
    pastNotes: 668,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 685,
    presentConstant: 6,
    futureLevel: '9+',
    futureNotes: 1087,
    futureConstant: 9.7,
    packName: 'Extend Archive',
  },
  {
    id: 219,
    jaTitle: 'Turbocharger',
    enTitle: 'Turbocharger',
    pastLevel: '2',
    pastNotes: 419,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 659,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 979,
    futureConstant: 9.0,
    packName: 'Extend Archive',
  },
  {
    id: 220,
    jaTitle: 'THE ULTIMACY',
    enTitle: 'THE ULTIMACY',
    pastLevel: '3',
    pastNotes: 749,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 919,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1304,
    futureConstant: 9.8,
    packName: 'Memory Archive',
  },
  {
    id: 221,
    jaTitle: '烈華RESONANCE',
    enTitle: 'REKKA RESONANCE',
    pastLevel: '5',
    pastNotes: 860,
    pastConstant: 5,
    presentLevel: '8',
    presentNotes: 1051,
    presentConstant: 8.3,
    futureLevel: '10+',
    futureNotes: 1212,
    futureConstant: 10.7,
    packName: 'Memory Archive',
  },
  {
    id: 222,
    jaTitle: 'Small Cloud Sugar Candy',
    enTitle: 'Small Cloud Sugar Candy',
    pastLevel: '3',
    pastNotes: 548,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 727,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 919,
    futureConstant: 9.1,
    packName: 'Esoteric Order',
  },
  {
    id: 223,
    jaTitle: 'AlterAle',
    enTitle: 'AlterAle',
    pastLevel: '3',
    pastNotes: 728,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 881,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1277,
    futureConstant: 9.7,
    packName: 'Esoteric Order',
  },
  {
    id: 224,
    jaTitle: '光速神授説 - Divine Light of Myriad -',
    enTitle: 'Divine Light of Myriad',
    pastLevel: '4',
    pastNotes: 798,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 835,
    presentConstant: 7.5,
    futureLevel: '10+',
    futureNotes: 1021,
    futureConstant: 10.8,
    packName: 'Esoteric Order',
  },
  {
    id: 225,
    jaTitle: 'Seclusion',
    enTitle: 'Seclusion',
    pastLevel: '4',
    pastNotes: 544,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 762,
    presentConstant: 7,
    futureLevel: '10',
    futureNotes: 1132,
    futureConstant: 10.6,
    packName: 'Esoteric Order',
  },
  {
    id: 226,
    jaTitle: '狂言綺語',
    enTitle: 'False Embellishment',
    pastLevel: '3',
    pastNotes: 576,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 684,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 969,
    futureConstant: 9.3,
    packName: 'Extend Archive',
  },
  {
    id: 227,
    jaTitle: 'HIVEMIND',
    enTitle: 'HIVEMIND',
    pastLevel: '4',
    pastNotes: 995,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 988,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1252,
    futureConstant: 10.0,
    packName: 'Extend Archive',
  },
  {
    id: 228,
    jaTitle: 'Mazy Metroplex',
    enTitle: 'Mazy Metroplex',
    pastLevel: '3',
    pastNotes: 453,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 704,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 952,
    futureConstant: 9.7,
    packName: 'WACCA Collaboration',
  },
  {
    id: 229,
    jaTitle: 'Quon(DJ Noriken)',
    enTitle: 'Quon(DJ Noriken)',
    pastLevel: '2',
    pastNotes: 425,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 496,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 749,
    futureConstant: 8.7,
    beyondLevel: '10',
    beyondNotes: 1044,
    beyondConstant: 10.3,
    packName: 'WACCA Collaboration',
  },
  {
    id: 230,
    jaTitle: 'with U',
    enTitle: 'with U',
    pastLevel: '2',
    pastNotes: 448,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 649,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 932,
    futureConstant: 9.4,
    packName: 'WACCA Collaboration',
  },
  {
    id: 231,
    jaTitle: 'GENOCIDER',
    enTitle: 'GENOCIDER',
    pastLevel: '4',
    pastNotes: 832,
    pastConstant: 4.5,
    presentLevel: '8',
    presentNotes: 1025,
    presentConstant: 8.5,
    futureLevel: '10+',
    futureNotes: 1483,
    futureConstant: 10.7,
    packName: 'WACCA Collaboration',
  },
  {
    id: 232,
    jaTitle: 'Let you DIVE! (nitro rmx)',
    enTitle: 'Let you DIVE! (nitro rmx)',
    pastLevel: '3',
    pastNotes: 608,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 819,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 1049,
    futureConstant: 9.3,
    packName: 'WACCA Collaboration',
  },
  {
    id: 233,
    jaTitle: 'Sheriruth (Laur Remix)',
    enTitle: 'Sheriruth (Laur Remix)',
    pastLevel: '4',
    pastNotes: 671,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 795,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1134,
    futureConstant: 10.6,
    packName: 'Memory Archive',
  },
  {
    id: 234,
    jaTitle: '緋纏いの宵',
    enTitle: 'Evening in Scarlet',
    pastLevel: '4',
    pastNotes: 530,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 638,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 922,
    futureConstant: 9.5,
    packName: 'Memory Archive',
  },
  {
    id: 235,
    jaTitle: 'blue comet',
    enTitle: 'blue comet',
    pastLevel: '3',
    pastNotes: 494,
    pastConstant: 3.5,
    presentLevel: '5',
    presentNotes: 645,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 776,
    futureConstant: 8.2,
    packName: 'Extend Archive',
  },
  {
    id: 236,
    jaTitle: 'ENERGY SYNERGY MATRIX',
    enTitle: 'ENERGY SYNERGY MATRIX',
    pastLevel: '3',
    pastNotes: 471,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 608,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 922,
    futureConstant: 9.6,
    packName: 'Extend Archive',
  },
  {
    id: 237,
    jaTitle: 'lastendconductor',
    enTitle: 'lastendconductor',
    pastLevel: '3',
    pastNotes: 726,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 901,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 1209,
    futureConstant: 9.4,
    beyondLevel: '10',
    beyondNotes: 1339,
    beyondConstant: 10.1,
    packName: 'Memory Archive',
  },
  {
    id: 238,
    jaTitle: 'goldenslaughterer',
    enTitle: 'goldenslaughterer',
    pastLevel: '4',
    pastNotes: 864,
    pastConstant: 4,
    presentLevel: '6',
    presentNotes: 880,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1326,
    futureConstant: 9.7,
    packName: 'Memory Archive',
  },
  {
    id: 239,
    jaTitle: 'G e n g a o z o',
    enTitle: 'G e n g a o z o',
    pastLevel: '4',
    pastNotes: 580,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 870,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1353,
    futureConstant: 10.2,
    packName: 'Extend Archive',
  },
  {
    id: 240,
    jaTitle: 'Redolent Shape',
    enTitle: 'Redolent Shape',
    pastLevel: '4',
    pastNotes: 570,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 717,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1088,
    futureConstant: 10.2,
    packName: 'Memory Archive',
  },
  {
    id: 241,
    jaTitle: 'Ascent',
    enTitle: 'Ascent',
    pastLevel: '3',
    pastNotes: 455,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 625,
    presentConstant: 6,
    futureLevel: '9+',
    futureNotes: 1023,
    futureConstant: 9.8,
    packName: 'Binary Enfold',
  },
  {
    id: 242,
    jaTitle: 'Cosmica',
    enTitle: 'Cosmica',
    pastLevel: '2',
    pastNotes: 428,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 566,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 773,
    futureConstant: 8.5,
    packName: 'Binary Enfold',
  },
  {
    id: 243,
    jaTitle: 'Live Fast Die Young',
    enTitle: 'Live Fast Die Young',
    pastLevel: '4',
    pastNotes: 565,
    pastConstant: 4.5,
    presentLevel: '8',
    presentNotes: 837,
    presentConstant: 8.1,
    futureLevel: '10',
    futureNotes: 1292,
    futureConstant: 10.6,
    packName: 'Binary Enfold',
  },
  {
    id: 244,
    jaTitle: '彩る夏の恋花火',
    enTitle: 'Summer Fireworks of Love',
    pastLevel: '3',
    pastNotes: 478,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 641,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1088,
    futureConstant: 9.9,
    packName: 'Memory Archive',
  },
  {
    id: 245,
    jaTitle: 'First Snow',
    enTitle: 'First Snow',
    pastLevel: '2',
    pastNotes: 366,
    pastConstant: 2.5,
    presentLevel: '4',
    presentNotes: 442,
    presentConstant: 4.5,
    futureLevel: '7',
    futureNotes: 578,
    futureConstant: 7.5,
    packName: 'Divided Heart',
  },
  {
    id: 246,
    jaTitle: 'Blocked Library',
    enTitle: 'Blocked Library',
    pastLevel: '3',
    pastNotes: 449,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 622,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 850,
    futureConstant: 9.3,
    packName: 'Divided Heart',
  },
  {
    id: 247,
    jaTitle: 'Lightning Screw',
    enTitle: 'Lightning Screw',
    pastLevel: '4',
    pastNotes: 611,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 811,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1192,
    futureConstant: 10.5,
    packName: 'Divided Heart',
  },
  {
    id: 248,
    jaTitle: 'nέο κόsmo',
    enTitle: 'nέο κόsmo',
    pastLevel: '4',
    pastNotes: 603,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 791,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 979,
    futureConstant: 9.7,
    packName: 'Divided Heart',
  },
  {
    id: 249,
    jaTitle: 'Blue Rose',
    enTitle: 'Blue Rose',
    pastLevel: '2',
    pastNotes: 595,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 669,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 955,
    futureConstant: 9.1,
    packName: 'Divided Heart',
  },
  {
    id: 250,
    jaTitle: 'Lights of Muse',
    enTitle: 'Lights of Muse',
    pastLevel: '2',
    pastNotes: 333,
    pastConstant: 2.5,
    presentLevel: '5',
    presentNotes: 438,
    presentConstant: 5.5,
    futureLevel: '8',
    futureNotes: 580,
    futureConstant: 8.7,
    packName: 'Muse Dash Collaboration',
  },
  {
    id: 251,
    jaTitle: 'Final Step!',
    enTitle: 'Final Step!',
    pastLevel: '3',
    pastNotes: 625,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 684,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 1056,
    futureConstant: 9.4,
    packName: 'Muse Dash Collaboration',
  },
  {
    id: 252,
    jaTitle: '秋の陽炎',
    enTitle: 'Haze of Autumn',
    pastLevel: '3',
    pastNotes: 533,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 733,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1077,
    futureConstant: 9.8,
    packName: 'Muse Dash Collaboration',
  },
  {
    id: 253,
    jaTitle: 'Medusa',
    enTitle: 'Medusa',
    pastLevel: '4',
    pastNotes: 550,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 645,
    presentConstant: 7,
    futureLevel: '10',
    futureNotes: 931,
    futureConstant: 10.2,
    packName: 'Muse Dash Collaboration',
  },
  {
    id: 254,
    jaTitle: 'init()',
    enTitle: 'init()',
    pastLevel: '4',
    pastNotes: 713,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 957,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1204,
    futureConstant: 9.8,
    packName: 'Memory Archive',
  },
  {
    id: 255,
    jaTitle: 'NULCTRL',
    enTitle: 'NULCTRL',
    pastLevel: '3',
    pastNotes: 407,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 410,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 715,
    futureConstant: 9.5,
    packName: 'Arcaea',
  },
  {
    id: 256,
    jaTitle: 'Ävril -Flicka i krans-',
    enTitle: 'Ävril -Flicka i krans-',
    pastLevel: '3',
    pastNotes: 459,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 607,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 851,
    futureConstant: 8.3,
    packName: 'Arcaea',
  },
  {
    id: 257,
    jaTitle: 'Sakura Fubuki',
    enTitle: 'Sakura Fubuki',
    pastLevel: '3',
    pastNotes: 526,
    pastConstant: 3.5,
    presentLevel: '5',
    presentNotes: 571,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 837,
    futureConstant: 9.3,
    packName: 'Arcaea',
  },
  {
    id: 258,
    jaTitle: 'NEO WINGS',
    enTitle: 'NEO WINGS',
    pastLevel: '4',
    pastNotes: 591,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 840,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1328,
    futureConstant: 10.2,
    packName: 'Memory Archive',
  },
  {
    id: 259,
    jaTitle: 'Macrocosmic Modulation',
    enTitle: 'Macrocosmic Modulation',
    pastLevel: '4',
    pastNotes: 608,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 794,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1117,
    futureConstant: 9.8,
    packName: 'Memory Archive',
  },
  {
    id: 260,
    jaTitle: 'Kissing Lucifer',
    enTitle: 'Kissing Lucifer',
    pastLevel: '4',
    pastNotes: 639,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 770,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1183,
    futureConstant: 10.4,
    packName: 'Memory Archive',
  },
  {
    id: 261,
    jaTitle: 'INTERNET OVERDOSE',
    enTitle: 'INTERNET OVERDOSE',
    pastLevel: '3',
    pastNotes: 430,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 578,
    presentConstant: 6.5,
    futureLevel: '8',
    futureNotes: 657,
    futureConstant: 8.4,
    packName: 'Memory Archive',
  },
  {
    id: 262,
    jaTitle: 'Aurgelmir',
    enTitle: 'Aurgelmir',
    pastLevel: '4',
    pastNotes: 540,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 765,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1100,
    futureConstant: 10.5,
    packName: 'Memory Archive',
  },
  {
    id: 263,
    jaTitle: 'Head BONK ache',
    enTitle: 'Head BONK ache',
    pastLevel: '4',
    pastNotes: 570,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 725,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 1061,
    futureConstant: 9.4,
    packName: 'Memory Archive',
  },
  {
    id: 264,
    jaTitle: 'DDD',
    enTitle: 'DDD',
    pastLevel: '2',
    pastNotes: 363,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 484,
    presentConstant: 6.5,
    futureLevel: '8',
    futureNotes: 653,
    futureConstant: 8.5,
    packName: 'Arcaea',
  },
  {
    id: 265,
    jaTitle: 'Prism',
    enTitle: 'Prism',
    pastLevel: '2',
    pastNotes: 476,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 544,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 785,
    futureConstant: 8,
    packName: 'Lanota Collaboration',
  },
  {
    id: 266,
    jaTitle: 'Protoflicker',
    enTitle: 'Protoflicker',
    pastLevel: '3',
    pastNotes: 510,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 633,
    presentConstant: 6,
    futureLevel: '9+',
    futureNotes: 1042,
    futureConstant: 9.8,
    packName: 'Lanota Collaboration',
  },
  {
    id: 267,
    jaTitle: 'Stasis',
    enTitle: 'Stasis',
    pastLevel: '4',
    pastNotes: 848,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 935,
    presentConstant: 7,
    futureLevel: '10+',
    futureNotes: 1521,
    futureConstant: 10.7,
    packName: 'Lanota Collaboration',
  },
  {
    id: 268,
    jaTitle: 'ピコPico*とらんすれーしょんっ！',
    enTitle: 'PICO-Pico-Translation!',
    pastLevel: '2',
    pastNotes: 543,
    pastConstant: 2,
    presentLevel: '6',
    presentNotes: 723,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 1049,
    futureConstant: 9.3,
    packName: 'Memory Archive',
  },
  {
    id: 269,
    jaTitle: 'ネコノテ・カリタガール',
    enTitle: "Dancin' on A Cat's Paw",
    pastLevel: '3',
    pastNotes: 417,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 593,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 891,
    futureConstant: 9.2,
    packName: 'Arcaea',
  },
  {
    id: 270,
    jaTitle: 'san skia',
    enTitle: 'san skia',
    pastLevel: '3',
    pastNotes: 670,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 783,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 1046,
    futureConstant: 8.3,
    packName: 'Arcaea',
  },
  {
    id: 271,
    jaTitle: 'μ',
    enTitle: 'μ',
    pastLevel: '3',
    pastNotes: 825,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 986,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1256,
    futureConstant: 9.7,
    packName: 'Memory Archive',
  },
  {
    id: 272,
    jaTitle: 'PUPA',
    enTitle: 'PUPA',
    pastLevel: '4',
    pastNotes: 374,
    pastConstant: 4.0,
    presentLevel: '7',
    presentNotes: 684,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1099,
    futureConstant: 10.4,
    packName: 'Memory Archive',
  },
  {
    id: 273,
    jaTitle: 'Altair (feat. *spiLa*)',
    enTitle: 'Altair (feat. *spiLa*)',
    pastLevel: '2',
    pastNotes: 670,
    pastConstant: 2.5,
    presentLevel: '6',
    presentNotes: 828,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 830,
    futureConstant: 8.3,
    packName: 'Arcaea',
  },
  {
    id: 274,
    jaTitle: '無機質世界に彩を',
    enTitle: 'Redraw the Colorless World',
    pastLevel: '4',
    pastNotes: 568,
    pastConstant: 4,
    presentLevel: '6',
    presentNotes: 680,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 886,
    futureConstant: 9.2,
    packName: 'Arcaea',
  },
  {
    id: 275,
    jaTitle: 'Trap Crow',
    enTitle: 'Trap Crow',
    pastLevel: '4',
    pastNotes: 876,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 898,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1074,
    futureConstant: 9.9,
    packName: 'Arcaea',
  },
  {
    id: 276,
    jaTitle: 'Defection',
    enTitle: 'Defection',
    pastLevel: '3',
    pastNotes: 588,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 800,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1141,
    futureConstant: 9.8,
    packName: 'Final Verdict',
  },
  {
    id: 277,
    jaTitle: 'Infinite Strife,',
    enTitle: 'Infinite Strife,',
    pastLevel: '4',
    pastNotes: 888,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 1081,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1511,
    futureConstant: 9.9,
    beyondLevel: '10+',
    beyondNotes: 1633,
    beyondConstant: 10.9,
    packName: 'Final Verdict',
  },
  {
    id: 278,
    jaTitle: '魔王',
    enTitle: 'World Ender',
    pastLevel: '4',
    pastNotes: 616,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 850,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1225,
    futureConstant: 9.9,
    beyondLevel: '11',
    beyondNotes: 1661,
    beyondConstant: 11.2,
    packName: 'Final Verdict',
  },
  {
    id: 279,
    jaTitle: 'Pentiment',
    enTitle: 'Pentiment',
    pastLevel: '6',
    pastNotes: 911,
    pastConstant: 6,
    presentLevel: '8',
    presentNotes: 1055,
    presentConstant: 8.4,
    futureLevel: '10',
    futureNotes: 1345,
    futureConstant: 10.3,
    beyondLevel: '11',
    beyondNotes: 1741,
    beyondConstant: 11.4,
    packName: 'Final Verdict',
  },
  {
    id: 280,
    jaTitle: 'Arcana Eden',
    enTitle: 'Arcana Eden',
    pastLevel: '5',
    pastNotes: 1097,
    pastConstant: 5.5,
    presentLevel: '8',
    presentNotes: 1310,
    presentConstant: 8.7,
    futureLevel: '10',
    futureNotes: 1792,
    futureConstant: 10.5,
    beyondLevel: '11',
    beyondNotes: 2134,
    beyondConstant: 11.4,
    packName: 'Final Verdict',
  },
  {
    id: 281,
    jaTitle: 'Testify',
    enTitle: 'Testify',
    pastLevel: '7',
    pastNotes: 1001,
    pastConstant: 7,
    presentLevel: '9',
    presentNotes: 1225,
    presentConstant: 9.4,
    futureLevel: '10+',
    futureNotes: 1766,
    futureConstant: 10.8,
    beyondLevel: '12',
    beyondNotes: 2221,
    beyondConstant: 12,
    packName: 'Final Verdict',
  },
  {
    id: 282,
    jaTitle: 'Loveless Dress',
    enTitle: 'Loveless Dress',
    pastLevel: '3',
    pastNotes: 537,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 630,
    presentConstant: 6.5,
    futureLevel: '9',
    futureNotes: 850,
    futureConstant: 9.6,
    packName: 'Final Verdict',
  },
  {
    id: 283,
    jaTitle: 'Last',
    enTitle: 'Last',
    pastLevel: '4',
    pastNotes: 680,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 781,
    presentConstant: 7,
    futureLevel: '9',
    futureNotes: 831,
    futureConstant: 9,
    packName: 'Final Verdict',
  },
  {
    id: 283,
    jaTitle: 'Last | Moment',
    enTitle: 'Last | Moment',
    beyondLevel: '9',
    beyondNotes: 888,
    beyondConstant: 9.6,
    packName: 'Final Verdict',
  },
  {
    id: 283.1, // ????
    jaTitle: 'Last | Eternity',
    enTitle: 'Last | Eternity',
    beyondLevel: '9+',
    beyondNotes: 790,
    beyondConstant: 9.6,
    packName: 'Final Verdict',
  },
  {
    id: 284,
    jaTitle: 'Callima Karma',
    enTitle: 'Callima Karma',
    pastLevel: '3',
    pastNotes: 1024,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 989,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1222,
    futureConstant: 9.8,
    packName: 'Final Verdict',
  },
  {
    id: 285,
    jaTitle: 'Ai Drew',
    enTitle: 'Ai Drew',
    pastLevel: '3',
    pastNotes: 694,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 732,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1066,
    futureConstant: 9.8,
    packName: 'O.N.G.E.K.I. Collaboration',
  },
  {
    id: 286,
    jaTitle: '心',
    enTitle: 'Heart',
    pastLevel: '1',
    pastNotes: 428,
    pastConstant: 1,
    presentLevel: '5',
    presentNotes: 575,
    presentConstant: 5,
    futureLevel: '9',
    futureNotes: 872,
    futureConstant: 9.3,
    packName: 'O.N.G.E.K.I. Collaboration',
  },
  {
    id: 287,
    jaTitle: 'FLUFFY FLASH',
    enTitle: 'FLUFFY FLASH',
    pastLevel: '3',
    pastNotes: 787,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 946,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1329,
    futureConstant: 9.8,
    packName: 'O.N.G.E.K.I. Collaboration',
  },
  {
    id: 288,
    jaTitle: 'Good bye, Merry-Go-Round.',
    enTitle: 'Good bye, Merry-Go-Round.',
    pastLevel: '4',
    pastNotes: 679,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 696,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1084,
    futureConstant: 10.5,
    packName: 'O.N.G.E.K.I. Collaboration',
  },
  {
    id: 289,
    jaTitle: 'LAMIA',
    enTitle: 'LAMIA',
    pastLevel: '5',
    pastNotes: 826,
    pastConstant: 5,
    presentLevel: '8',
    presentNotes: 885,
    presentConstant: 8.3,
    futureLevel: '10+',
    futureNotes: 1385,
    futureConstant: 10.9,
    packName: 'O.N.G.E.K.I. Collaboration',
  },
  {
    id: 290,
    jaTitle: 'Free Myself',
    enTitle: 'Free Myself',
    pastLevel: '4',
    pastNotes: 662,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 785,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1132,
    futureConstant: 10,
    packName: 'Memory Archive',
  },
  {
    id: 291,
    jaTitle: 'cocoro*cosmetic',
    enTitle: 'cocoro*cosmetic',
    pastLevel: '3',
    pastNotes: 525,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 687,
    presentConstant: 6,
    futureLevel: '9',
    futureNotes: 1025,
    futureConstant: 9.2,
    packName: 'Memory Archive',
  },
  {
    id: 292,
    jaTitle: 'Capella',
    enTitle: 'Capella',
    pastLevel: '4',
    pastNotes: 884,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 904,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1159,
    futureConstant: 10.2,
    packName: 'Memory Archive',
  },
  {
    id: 293,
    jaTitle: 'だいあるのーと',
    enTitle: 'Dialnote',
    pastLevel: '3',
    pastNotes: 393,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 548,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 684,
    futureConstant: 8.1,
    packName: 'Arcaea',
  },
  {
    id: 294,
    jaTitle: '月に叢雲華に風',
    enTitle: '月に叢雲華に風',
    pastLevel: '3',
    pastNotes: 468,
    pastConstant: 3,
    presentLevel: '6',
    presentNotes: 610,
    presentConstant: 6,
    futureLevel: '8',
    futureNotes: 740,
    futureConstant: 8.4,
    packName: 'World Extend',
  },
  {
    id: 295,
    jaTitle: 'MANTIS (Arcaea Ultra-Bloodrush VIP)',
    enTitle: 'MANTIS (Arcaea Ultra-Bloodrush VIP)',
    pastLevel: '3',
    pastNotes: 575,
    pastConstant: 3,
    presentLevel: '5',
    presentNotes: 760,
    presentConstant: 5.5,
    futureLevel: '9',
    futureNotes: 1014,
    futureConstant: 9.3,
    packName: 'World Extend',
  },
  {
    id: 296,
    jaTitle: 'World Fragments III(radio edit)',
    enTitle: 'World Fragments III(radio edit)',
    pastLevel: '3',
    pastNotes: 777,
    pastConstant: 3.5,
    presentLevel: '7',
    presentNotes: 999,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1387,
    futureConstant: 9.8,
    packName: 'World Extend',
  },
  {
    id: 297,
    jaTitle: 'Astra walkthrough',
    enTitle: 'Astra walkthrough',
    pastLevel: '4',
    pastNotes: 769,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 886,
    presentConstant: 7.5,
    futureLevel: '9+',
    futureNotes: 1191,
    futureConstant: 9.9,
    packName: 'World Extend',
  },
  {
    id: 298,
    jaTitle: 'Chronicle',
    enTitle: 'Chronicle',
    pastLevel: '5',
    pastNotes: 928,
    pastConstant: 5,
    presentLevel: '8',
    presentNotes: 1077,
    presentConstant: 8.5,
    futureLevel: '10',
    futureNotes: 1264,
    futureConstant: 10.4,
    packName: 'World Extend',
  },
  {
    id: 299,
    jaTitle: 'NULL APOPHENIA',
    enTitle: 'NULL APOPHENIA',
    pastLevel: '4',
    pastNotes: 990,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 1098,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1299,
    futureConstant: 10.6,
    packName: 'Memory Archive',
  },
  {
    id: 300,
    jaTitle: 'CYCLES',
    enTitle: 'CYCLES',
    pastLevel: '2',
    pastNotes: 389,
    pastConstant: 2,
    presentLevel: '5',
    presentNotes: 430,
    presentConstant: 5,
    futureLevel: '8',
    futureNotes: 695,
    futureConstant: 8.8,
    packName: 'maimai Collaboration',
  },
  {
    id: 301,
    jaTitle: 'MAXRAGE',
    enTitle: 'MAXRAGE',
    pastLevel: '3',
    pastNotes: 696,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 760,
    presentConstant: 6.5,
    futureLevel: '9+',
    futureNotes: 1184,
    futureConstant: 9.9,
    packName: 'maimai Collaboration',
  },
  {
    id: 302,
    jaTitle: '[X]',
    enTitle: '[X]',
    pastLevel: '4',
    pastNotes: 594,
    pastConstant: 4.5,
    presentLevel: '7',
    presentNotes: 782,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1190,
    futureConstant: 10.4,
    packName: 'maimai Collaboration',
  },
  {
    id: 303,
    jaTitle: 'TEmPTaTiON',
    enTitle: 'TEmPTaTiON',
    pastLevel: '5',
    pastNotes: 627,
    pastConstant: 5,
    presentLevel: '8',
    presentNotes: 768,
    presentConstant: 8.2,
    futureLevel: '10+',
    futureNotes: 1099,
    futureConstant: 10.9,
    packName: 'maimai Collaboration',
  },
  {
    id: 304,
    jaTitle: 'Crimson Throne',
    enTitle: 'Crimson Throne',
    pastLevel: '4',
    pastNotes: 727,
    pastConstant: 4,
    presentLevel: '8',
    presentNotes: 1079,
    presentConstant: 8,
    futureLevel: '10',
    futureNotes: 1313,
    futureConstant: 10.4,
    packName: 'Memory Archive',
  },
  {
    id: 305,
    jaTitle: 'Manic Jeer',
    enTitle: 'Manic Jeer',
    pastLevel: '4',
    pastNotes: 698,
    pastConstant: 4,
    presentLevel: '7',
    presentNotes: 875,
    presentConstant: 7.5,
    futureLevel: '10',
    futureNotes: 1286,
    futureConstant: 10.6,
    packName: 'Memory Archive',
  },
  {
    id: 306,
    jaTitle: '緋色月下、狂咲ノ絶 (nayuta 2017 ver.)',
    enTitle: 'Hiiro Gekka, Kyoushou no Zetsu (nayuta 2017 ver.)',
    pastLevel: '3',
    pastNotes: 719,
    pastConstant: 3.5,
    presentLevel: '6',
    presentNotes: 709,
    presentConstant: 6.5,
    futureLevel: '10',
    futureNotes: 1126,
    futureConstant: 10.3,
    packName: 'Memory Archive',
  },
  {
    id: 307,
    jaTitle: "Let's Rock (Arcaea mix)",
    enTitle: "Let's Rock (Arcaea mix)",
    pastLevel: '3',
    pastNotes: 541,
    pastConstant: 3,
    presentLevel: '7',
    presentNotes: 963,
    presentConstant: 7,
    futureLevel: '9+',
    futureNotes: 1177,
    futureConstant: 9.7,
    packName: 'World Extend',
  },
  {
    id: 308,
    jaTitle: 'PRIMITIVE LIGHTS',
    enTitle: 'PRIMITIVE LIGHTS',
    pastLevel: '5',
    pastNotes: 785,
    // pastConstant: null,
    presentLevel: '8',
    presentNotes: 1073,
    presentConstant: 8.5,
    futureLevel: '10+',
    futureNotes: 1524,
    futureConstant: 10.7,
    packName: 'Memory Archive',
  },
]);
