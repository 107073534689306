import { Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { bestPotentialsAtom } from '@/states/derived/bestPotentials';

export const BestPotential: React.FC = () => {
  const bestPotentials = useAtomValue(bestPotentialsAtom);
  const bestPotential = useMemo(
    () => bestPotentials.reduce((acc, pot) => acc + pot.potential, 0) / 30,
    [bestPotentials]
  );

  return (
    <Typography component="h2" variant="h6">
      Best枠: {bestPotential.toFixed(2)}
    </Typography>
  );
};
