import { AddCircle, Cancel, Sort } from '@mui/icons-material';
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useAtom } from 'jotai';
import { rowSorterAtom } from '@/states/rowSorter';
import { Column, columns } from '@/types';

const SortTable: React.FC = () => {
  const [sortOptions, dispatchSortOptions] = useAtom(rowSorterAtom);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={6}>ソート</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortOptions.map((opt, i) => (
            <TableRow key={i}>
              <TableCell padding="checkbox">{i + 1}.</TableCell>
              <TableCell>
                <Select
                  value={opt.key}
                  onChange={(ev) => {
                    const key = ev.target.value as Column;
                    dispatchSortOptions({
                      type: 'replace',
                      index: i,
                      key,
                    });
                  }}>
                  {columns.map(([columnEn, columnJa], j) => (
                    <MenuItem value={columnEn} key={j}>
                      {columnJa}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell padding="checkbox">
                <Button
                  onClick={() =>
                    dispatchSortOptions({
                      type: 'switch',
                      index: i,
                    })
                  }>
                  <Sort sx={{ transform: `scale(1, ${opt.asc ? -1 : 1})` }} />
                </Button>
              </TableCell>
              <TableCell padding="checkbox">
                <Button
                  onClick={() => {
                    if (sortOptions.length !== 1)
                      dispatchSortOptions({
                        type: 'remove',
                        index: i,
                      });
                  }}>
                  <Cancel />
                </Button>
              </TableCell>
              <TableCell padding="checkbox">
                <Button
                  onClick={() =>
                    dispatchSortOptions({
                      type: 'insert',
                      index: i,
                    })
                  }>
                  <AddCircle />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SortTable;
