import { atomWithReducer } from 'jotai/utils';
import clone from 'lodash/clone';
import { ChartKey } from '@/types';

type Dispatch = { type: 'add'; value: ChartKey } | { type: 'reset' };

// keys of updatingScoreFamily
export const updatingScoreListAtom = atomWithReducer(
  new Set<ChartKey>(),
  (prev, arg: Dispatch) => {
    switch (arg.type) {
      case 'add': {
        const next = clone(prev);
        next.add(arg.value);
        return next;
      }
      case 'reset':
        return new Set();
    }
  }
);
