import { atom } from 'jotai';
import { fumenKeys } from '@/data';
import { rowDataFamily } from '@/states/derived/rowData';
import { ChartKey } from '@/types';
import { generateRowKeyFromFumen } from '@/utils';

const BEST_NUM = 30; // magic number: #bests

// derived: read-only
export const bestPotentialsAtom = atom((get) => {
  const potentials: { key: ChartKey; potential: number }[] = [];
  fumenKeys.forEach((key) => {
    const row = get(rowDataFamily(key));
    if (isNaN(row.potential)) return;
    const i = lowerBound(
      potentials,
      row,
      (left, right) => left.potential > right.potential // 降順に並べたいんだった……
    );
    potentials.splice(i, 0, {
      key: generateRowKeyFromFumen(row),
      potential: row.potential,
    });
    if (potentials.length > BEST_NUM) {
      // 最低ポが複数あったらキープしたい(複数個一気に抜けるパターンがあり、めんどい)
      // if (potentials[potentials.length - 1] < potentials[potentials.length - 2]) {
      potentials.pop();
      // }
    }
  });
  return potentials;
});

function lowerBound<T, U>(
  list: T[],
  query: U,
  lt: (l: T, r: U) => boolean
): number {
  let lo = -1; // https://www.pandanoir.info/entry/2015/12/26/190000
  let hi = list.length;
  while (hi - lo > 1) {
    const mid = lo + Math.floor((hi - lo) / 2);
    if (lt(list[mid], query)) {
      lo = mid;
    } else {
      hi = mid;
    }
  }
  return hi;
}
