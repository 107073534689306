import { MenuItem, TableCell, TableRow, TextField } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { rowDataFamily } from '@/states/derived/rowData';
import { updatingScoreFamily } from '@/states/updatingScore';
import { updatingScoreListAtom } from '@/states/updatingScoreList';
import { ChartKey, ClearLamp, clearLamps, Column } from '@/types';

type Props = {
  dataKey: ChartKey;
  isInBest: boolean;
  isEditing: boolean;
  columnFilter: { [K in Column]: boolean };
};

const EditableTableRow: React.FC<Props> = (props) => {
  const rowData = useAtomValue(rowDataFamily(props.dataKey));
  const [updatingScore, setUpdatingScore] = useAtom(
    updatingScoreFamily(props.dataKey)
  );
  const dispatchUpdatingScoreList = useSetAtom(updatingScoreListAtom);

  useEffect(() => {
    if (props.isEditing) {
      setUpdatingScore(RESET);
    }
  }, [props.isEditing, setUpdatingScore]);

  return (
    <TableRow
      hover
      sx={
        props.isInBest
          ? {
              background: 'rgba(240, 235, 164, 0.5)',
            }
          : {}
      }>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          display: props.columnFilter.jaTitle ? 'table-cell' : 'none',
        }}>
        {rowData.jaTitle}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          display: props.columnFilter.enTitle ? 'table-cell' : 'none',
        }}>
        {rowData.enTitle}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          display: props.columnFilter.packName ? 'table-cell' : 'none',
        }}>
        {rowData.packName}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          display: props.columnFilter.difficulty ? 'table-cell' : 'none',
        }}>
        {rowData.difficulty}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          display: props.columnFilter.level ? 'table-cell' : 'none',
        }}>
        {rowData.level}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          display: props.columnFilter.constant ? 'table-cell' : 'none',
          textAlign: 'right',
        }}>
        {rowData.constant.toFixed(1)}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          display: props.columnFilter.notes ? 'table-cell' : 'none',
          textAlign: 'right',
        }}>
        {rowData.notes}
      </TableCell>
      <TableCell
        sx={{ display: props.columnFilter.score ? 'table-cell' : 'none' }}>
        {props.isEditing ? (
          <NumberFormat
            customInput={TextField}
            sx={{ width: '10em' }}
            value={updatingScore.score}
            allowNegative={false}
            thousandSeparator
            onValueChange={({ floatValue: score = 0 }) => {
              setUpdatingScore({
                ...updatingScore,
                score: Math.floor(score),
              });
              dispatchUpdatingScoreList({
                type: 'add',
                value: props.dataKey,
              });
            }}
          />
        ) : (
          <NumberFormat
            displayType="text"
            thousandSeparator
            style={{ display: 'block', textAlign: 'right' }}
            value={rowData.score}
          />
        )}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          display: props.columnFilter.potential ? 'table-cell' : 'none',
          textAlign: 'right',
        }}>
        {rowData.potential.toFixed(2)}
      </TableCell>
      <TableCell
        sx={{ display: props.columnFilter.clearLamp ? 'table-cell' : 'none' }}>
        {props.isEditing ? (
          <TextField
            select
            fullWidth
            value={updatingScore.clearLamp}
            onChange={(ev) => {
              const clearLamp = ev.target.value as ClearLamp;
              setUpdatingScore({
                ...updatingScore,
                clearLamp,
              });
              dispatchUpdatingScoreList({
                type: 'add',
                value: props.dataKey,
              });
            }}>
            {clearLamps.map(([c, _]) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          rowData.clearLamp
        )}
      </TableCell>
    </TableRow>
  );
};

export default EditableTableRow;
