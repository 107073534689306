// DIコンテナ: Context使えば簡単じゃんという話

import { createContext, useContext } from 'react';
import { Database } from '@/database';
import firebaseJotaiDatabase from '@/database/firebaseJotaiDatabase';
import { UserData } from '@/types';

const DatabaseContext = createContext<Database<UserData>>(
  firebaseJotaiDatabase
);

// 値を変えることがないので別にこいつ使わなくても何の問題もなかったり
export const DatabaseProvider: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <DatabaseContext.Provider value={firebaseJotaiDatabase}>
      {props.children}
    </DatabaseContext.Provider>
  );
};

export const useDatabase = () => {
  const database = useContext(DatabaseContext);

  return database;
};
