import { onAuthStateChanged, User } from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import { Database } from '.';
import { authApp, firestoreApp } from '@/firebaseApps';
import { ChartKey, UserData } from '@/types';
import { querySnapshotAsObject } from '@/utils';

// implementation of Database by Firebase/Firestore
let firebaseUser: User | null = null;

onAuthStateChanged(authApp, (newUser) => {
  firebaseUser = newUser;
});

const getUserData: Database<UserData>['getUserData'] = async () => {
  if (firebaseUser == null) return null;

  const docPath = `/users/${firebaseUser.uid}`;
  return (await getDoc(doc(firestoreApp, docPath))).data() as UserData;
};

const checkUsernameExists: Database<UserData>['checkUsernameExists'] = async (
  username
) => {
  if (firebaseUser == null) return true;

  const docPath = `/userNames/${username}`;
  const document = await getDoc(doc(firestoreApp, docPath));
  return document.exists();
};

const setUserData: Database<UserData>['setUserData'] = async (
  user,
  originalName
) => {
  if (firebaseUser == null) return;
  if (user.name == null && user.publicity == null) return;

  if (originalName !== user.name) {
    await setDoc(doc(firestoreApp, `/userNames/${user.name}`), {
      userId: firebaseUser.uid,
    });
    if (originalName != null && originalName.length !== 0) {
      await deleteDoc(doc(firestoreApp, `/userNames/${originalName}`));
    }
  }

  await setDoc(doc(firestoreApp, `/users/${firebaseUser.uid}`), user, {
    merge: true,
  });
};

const getChartScoreData: Database<UserData>['getChartScoreData'] = async () => {
  if (firebaseUser == null) return {};
  const collectionId = `users/${firebaseUser.uid}/scores`;

  const documents = await getDocs(collection(firestoreApp, collectionId));
  return querySnapshotAsObject(documents);
};

const setChartScoreData: Database<UserData>['setChartScoreData'] = async (
  chartScoreData
) => {
  if (firebaseUser == null) return;
  const collectionId = `/users/${firebaseUser.uid}/scores`;

  const saveJobs: Promise<void>[] = [];
  (Object.keys(chartScoreData) as ChartKey[]).forEach((key) => {
    saveJobs.push(
      setDoc(doc(firestoreApp, collectionId, key), chartScoreData[key])
    );
  });
};

export default {
  getUserData,
  setUserData,
  checkUsernameExists,
  getChartScoreData,
  setChartScoreData,
} as Database<UserData>;
