import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { chartScoreDataAtom } from '@/states/chartScoreData';
import { ChartKey, ChartScoreData } from '@/types';

// proxy: each datum of local cache (i.e. chartScoreDataAtom)
export const singleChartScoreDataFamily = atomFamily((key: ChartKey) =>
  atom(
    (get) => get(chartScoreDataAtom)[key],
    (_, set, arg: ChartScoreData) => {
      // save to local cache
      set(chartScoreDataAtom, { type: 'update', key, value: arg });
      // todo: DBへのwrite-back
    }
  )
);
