import { atomWithReducer } from 'jotai/utils';
import clone from 'lodash/clone';
import { RowData, clearLamps, Column, difficulties, levels } from '@/types';

type ColumnComparator = (left: RowData, right: RowData) => number;

const comparators: { [col in Column]: ColumnComparator } = {
  level: (left, right) =>
    levels.findIndex((level) => level[0] === right.level) -
    levels.findIndex((level) => level[0] === left.level),
  clearLamp: (left, right) =>
    clearLamps.findIndex((lamp) => lamp[0] === right.clearLamp) -
    clearLamps.findIndex((lamp) => lamp[0] === left.clearLamp),
  difficulty: (left, right) =>
    difficulties.findIndex((diff) => diff[0] === right.difficulty) -
    difficulties.findIndex((diff) => diff[0] === left.difficulty),
  constant: (left, right) => right.constant - left.constant,
  notes: (left, right) => right.notes - left.notes,
  score: (left, right) => right.score - left.score,
  jaTitle: (left, right) => {
    if (left.jaTitle.toLowerCase() === right.jaTitle.toLowerCase()) {
      return 0;
    } else {
      return left.jaTitle.toLowerCase() < right.jaTitle.toLowerCase() ? 1 : -1;
    }
  },
  enTitle: (left, right) => {
    if (left.enTitle.toLowerCase() === right.enTitle.toLowerCase()) {
      return 0;
    } else {
      return left.enTitle.toLowerCase() < right.enTitle.toLowerCase() ? 1 : -1;
    }
  },
  packName: (left, right) => {
    if (left.packName.toLowerCase() === right.packName.toLowerCase()) {
      return 0;
    } else {
      return left.packName.toLowerCase() < right.packName.toLowerCase()
        ? 1
        : -1;
    }
  },
  potential: (left, right) => right.potential - left.potential,
};

type SortOption = {
  key: Column;
  comparator: ColumnComparator;
  asc: boolean;
};

type Dispatch =
  | {
      type: 'insert';
      index: number;
    }
  | {
      type: 'switch';
      index: number;
    }
  | {
      type: 'replace';
      index: number;
      key: Column;
    }
  | {
      type: 'remove';
      index: number;
    };

export const rowSorterAtom = atomWithReducer(
  [
    {
      key: 'potential',
      comparator: comparators.potential,
      asc: false,
    },
  ] as SortOption[],
  (prev, arg: Dispatch) => {
    const next = clone(prev);
    switch (arg?.type) {
      case 'insert':
        next.splice(arg.index + 1, 0, {
          key: 'potential',
          comparator: comparators.potential,
          asc: false,
        });
        break;
      case 'remove':
        next.splice(arg.index, 1);
        break;
      case 'replace':
        next[arg.index].key = arg.key;
        next[arg.index].comparator = comparators[arg.key];
        break;
      case 'switch':
        next[arg.index].asc = !next[arg.index].asc;
        break;
    }
    return next;
  }
);
