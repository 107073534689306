import {
  AppBar,
  Button,
  Dialog,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/system';
import { GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { useAtom } from 'jotai';
import { forwardRef, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import StyledFirebaseAuth from './StyledFirebaseAuth';
import { VERSION } from '@/data';
import { authApp } from '@/firebaseApps';
import { userAtom } from '@/states/user';

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/account',
  signInOptions: [GoogleAuthProvider.PROVIDER_ID],
};

const Transition = forwardRef(function Transition(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Header: React.FC = () => {
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);

  const [user, setUser] = useAtom(userAtom);
  useEffect(() => {
    onAuthStateChanged(authApp, (user) => {
      setUser(user);
    });
  }, [setUser]);

  return (
    <AppBar color="primary" position="absolute">
      <Toolbar>
        <Typography component="h1" variant="h6">
          <Box
            component={RouterLink}
            sx={{ color: 'inherit', textDecoration: 'none' }}
            to="/">
            Arcaea score tool(仮)
          </Box>
        </Typography>
        <Box component="span">({VERSION}準拠)</Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        {user ? (
          <>
            <Button
              sx={{ color: 'white' }}
              component={RouterLink}
              to="/account">
              Account
            </Button>
            <Button sx={{ color: 'white' }} onClick={() => authApp.signOut()}>
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              sx={{ color: 'white' }}
              onClick={() => setLoginModalIsOpen(true)}>
              Login
            </Button>
            <Dialog
              open={loginModalIsOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setLoginModalIsOpen(false)}>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={authApp} />
            </Dialog>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
