import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

const appConfig = {
  apiKey: 'AIzaSyC95pVkJuovNxOlR-_3b7oPntzHTF-V3M4',
  authDomain: 'arcaea-scoretool.firebaseapp.com',
  databaseURL: 'https://arcaea-scoretool.firebaseio.com',
  projectId: 'arcaea-scoretool',
  storageBucket: 'arcaea-scoretool.appspot.com',
  messagingSenderId: '899287308851',
  appId: '1:899287308851:web:cf9af1e2cc6db5a3392146',
};

export const firebaseApp = initializeApp(appConfig);
export const authApp = getAuth(firebaseApp);

const firestoreApp = getFirestore(firebaseApp);
if (window.location.hostname === 'localhost') {
  connectFirestoreEmulator(firestoreApp, 'localhost', 8080);
}

export { firestoreApp };
