import { Cancel as CancelIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import {
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Zoom,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import EditableTableRow from './TableRow';
import { bestPotentialsAtom } from '@/states/derived/bestPotentials';
import { displayingChartKeyListAtom } from '@/states/displayingChartDataList';
import { columnFilterAtom } from '@/states/filters/columnFilter';
import { columns } from '@/types';

type Props = {
  onStart?: () => void;
  onReset?: () => void;
  onFinish?: () => void;
};

const EditableTable: React.FC<Props> = (props) => {
  const theme = useTheme();

  const [isEditing, setIsEditing] = useState(false);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const displayingChartKeyList = useAtomValue(displayingChartKeyListAtom);
  const bestPotentials = useAtomValue(bestPotentialsAtom);
  const columnFilter = useAtomValue(columnFilterAtom);

  return (
    <TableContainer sx={{ maxHeight: '600px' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((c, i) => (
              <TableCell
                key={i}
                sx={{
                  whiteSpace: 'nowrap',
                  display: columnFilter[c[0]] ? 'table-cell' : 'none',
                }}>
                {c[1]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayingChartKeyList.map((key) => (
            <EditableTableRow
              key={key}
              dataKey={key}
              isInBest={bestPotentials.map((p) => p.key).includes(key)}
              columnFilter={columnFilter}
              isEditing={isEditing}
            />
          ))}
        </TableBody>
      </Table>
      <Zoom
        in={!isEditing}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${!isEditing ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit>
        <Fab
          sx={{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
          }}
          onClick={() => {
            setIsEditing(true);
            props.onStart?.();
          }}>
          <EditIcon />
        </Fab>
      </Zoom>
      <Zoom
        in={isEditing}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${isEditing ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit>
        <Fab
          sx={{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(10), // ↓の幅を取れるなら、それ+spacing(2)
          }}
          onClick={() => {
            setIsEditing(false);
            props.onReset?.();
          }}>
          <CancelIcon />
        </Fab>
      </Zoom>
      <Zoom
        in={isEditing}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${isEditing ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit>
        <Fab
          sx={{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
          }}
          onClick={() => {
            setIsEditing(false);
            props.onFinish?.();
          }}>
          <SendIcon />
        </Fab>
      </Zoom>
    </TableContainer>
  );
};

export default EditableTable;
