import { atomWithReducer } from 'jotai/utils';
import { ChartKey, ChartScoreData } from '@/types';

type Dispatch =
  | { type: 'set'; value: { [key: ChartKey]: ChartScoreData | undefined } }
  | {
      type: 'update';
      key: ChartKey;
      value: ChartScoreData;
    };

// local cache of DB (not intended to be used directly, except when initializing)
export const chartScoreDataAtom = atomWithReducer(
  {} as {
    [key: ChartKey]: ChartScoreData | undefined;
  },
  (prev, arg: Dispatch) => {
    switch (arg.type) {
      case 'set':
        return arg.value;
      case 'update':
        return {
          ...prev,
          [arg.key]: {
            ...prev[arg.key],
            ...arg.value,
          },
        };
    }
  }
);
