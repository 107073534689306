export const difficulties = [
  ['Past', 'Past'],
  ['Present', 'Present'],
  ['Future', 'Future'],
  ['Beyond', 'Beyond'],
] as const;
export type Difficulty = typeof difficulties[number][0];

export const levels = [
  ['1', '1'],
  ['2', '2'],
  ['3', '3'],
  ['4', '4'],
  ['5', '5'],
  ['6', '6'],
  ['7', '7'],
  ['8', '8'],
  ['9', '9'],
  ['9+', '9+'],
  ['10', '10'],
  ['10+', '10+'],
  ['11', '11'],
  ['12', '12'],
] as const;
export type Level = typeof levels[number][0];

export type SongData = {
  id: number;
  jaTitle: string;
  enTitle: string;
  pastConstant: number;
  pastLevel: Level;
  pastNotes: number;
  presentConstant: number;
  presentLevel: Level;
  presentNotes: number;
  futureConstant: number;
  futureLevel: Level;
  futureNotes: number;
  beyondConstant?: number;
  beyondLevel?: Level;
  beyondNotes?: number;
  packName: string;
};

export const clearLamps = [
  ['No Play', 'No Play'],
  ['Track Lost', 'Track Lost'],
  ['Track Complete (Easy)', 'Track Complete (Easy)'],
  ['Track Complete', 'Track Complete'],
  ['Track Complete (Hard)', 'Track Complete (Hard)'],
  ['Full Recall', 'Full Recall'],
  ['Pure Memory', 'Pure Memory'],
] as const;
export type ClearLamp = typeof clearLamps[number][0];

export type RowData = {
  id: number;
  jaTitle: string;
  enTitle: string;
  packName: string;
  difficulty: Difficulty;
  level: Level;
  constant: number;
  notes: number;
  score: number;
  potential: number;
  clearLamp: ClearLamp;
};

// Object.keys doesn't keep order
// [(処理用のASCII文字列), (表示用のUnicode文字列)][]
export const columns = [
  ['jaTitle', '曲名'],
  ['enTitle', '曲名(英語)'],
  ['packName', 'パック'],
  ['difficulty', '難易度'],
  ['level', 'レベル'],
  ['constant', '譜面定数'],
  ['notes', 'ノーツ数'],
  ['score', 'スコア'],
  ['potential', 'ポテンシャル値'],
  ['clearLamp', 'クリアランプ'],
] as const;
export type Column = typeof columns[number][0];

export type ChartScoreData = {
  score: number;
  clearLamp: ClearLamp;
};

export type ChartKey = `${number}-${Difficulty}`;

export type ChartData = {
  id: number;
  jaTitle: string;
  enTitle: string;
  packName: string;
  difficulty: Difficulty;
  level: Level;
  constant: number;
  notes: number;
};

export type UserData = {
  name: string;
  publicity: boolean; // true => public?
};
