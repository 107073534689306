// あとで分割する
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { BestPotential } from '@/components/BestPotential';
import Checkboxes from '@/components/Checkboxes';
import EditableTable from '@/components/EditableTable';
import SortTable from '@/components/SortTable';
import { useDatabase } from '@/di/database';
import { chartScoreDataAtom } from '@/states/chartScoreData';
import { singleChartScoreDataFamily } from '@/states/derived/singleChartScoreData';
import { columnFilterAtom } from '@/states/filters/columnFilter';
import { difficultyFilterAtom } from '@/states/filters/rowFilters/difficulty';
import { levelFilterAtom } from '@/states/filters/rowFilters/level';
import { updatingScoreFamily } from '@/states/updatingScore';
import { updatingScoreListAtom } from '@/states/updatingScoreList';
import { userAtom } from '@/states/user';
import {
  ChartKey,
  ChartScoreData,
  columns,
  difficulties,
  levels,
} from '@/types';

const Main: React.FC = () => {
  const user = useAtomValue(userAtom);
  const database = useDatabase();

  const dispatchChartScoreData = useSetAtom(chartScoreDataAtom);
  const [loading, setLoading] = useState(true);

  const initializeUpdate = useAtomCallback(
    useCallback((_, set) => {
      set(updatingScoreListAtom, { type: 'reset' });
    }, [])
  );

  const saveScoreJotai = useAtomCallback(
    useCallback(
      async (get, set) => {
        const keys = get(updatingScoreListAtom);
        const updates: { [key: ChartKey]: ChartScoreData } = {};
        keys.forEach((key) => {
          const value = get(updatingScoreFamily(key));
          set(singleChartScoreDataFamily(key), value);
          updates[key] = value;
        });
        await database.setChartScoreData(updates);
      },
      [database]
    )
  );

  useEffect(() => {
    database
      .getChartScoreData()
      .then((saved) => {
        dispatchChartScoreData({ type: 'set', value: saved });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, dispatchChartScoreData, database]);

  return (
    <Container>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: '#fff',
        }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {user ? (
        <>
          <Box my={2}>
            <BestPotential />
          </Box>
          <Box my={2}>
            <Typography component="h2" variant="h6">
              行フィルタ
            </Typography>
            <Box component="span" fontWeight="bold">
              レベル
            </Box>
            <Checkboxes labels={levels} atom={levelFilterAtom} />
            <Box component="span" fontWeight="bold">
              譜面種別
            </Box>
            <Checkboxes labels={difficulties} atom={difficultyFilterAtom} />
          </Box>
          <Box my={2}>
            <Typography component="h2" variant="h6">
              列フィルタ
            </Typography>
            <Checkboxes labels={columns} atom={columnFilterAtom} />
          </Box>
          <Box my={2}>
            <SortTable />
          </Box>
          <Box my={2} sx={{ overflowX: 'scroll' }}>
            <EditableTable
              onStart={initializeUpdate}
              onFinish={saveScoreJotai}
            />
          </Box>
        </>
      ) : (
        <Typography>Login to use!</Typography>
      )}
    </Container>
  );
};

export default Main;
