import { Checkbox as MaterialCheckbox } from '@mui/material';
import { useAtom, WritableAtom } from 'jotai';

type Props = {
  atom: WritableAtom<boolean, boolean>;
};

export const Checkbox: React.FC<Props> = (props) => {
  const [checked, setChecked] = useAtom(props.atom);

  return (
    <MaterialCheckbox checked={checked} onChange={() => setChecked(!checked)} />
  );
};
