import { StyledEngineProvider, ThemeProvider, Toolbar } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Provider as JotaiProvider } from 'jotai';
import { Route, Routes } from 'react-router';
import Header from '@/components/Header';
import Account from '@/pages/Account';
import Main from '@/pages/Main';

const Default: React.FC = () => null;

const theme = createTheme();

const App: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <JotaiProvider>
        <ThemeProvider theme={theme}>
          <Header />
          <Toolbar /> {/* placeholder */}
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/account" element={<Account />} />
            {/* <Route path="/user/:userName" element={<User />} /> */}
            <Route path="*" element={<Default />} />
          </Routes>
        </ThemeProvider>
      </JotaiProvider>
    </StyledEngineProvider>
  );
};

export default App;
