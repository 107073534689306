import type { QuerySnapshot, DocumentData } from 'firebase/firestore';
import {
  SongData,
  difficulties,
  Difficulty,
  Level,
  RowData,
  ChartScoreData,
  ChartKey,
  ChartData,
} from '@/types';

export function calculatePotential(
  constant: number | undefined,
  score: number
): number {
  if (constant == null) return NaN;
  if (score >= 10_000_000) return constant + 2.0;
  else if (score >= 9_800_000)
    return constant + 1.0 + (score - 9_800_000) / 200_000;
  else return Math.max(constant + (score - 9_500_000) / 300_000, 0);
}

export function calculateBestPotential(chartData: RowData[]): number {
  const potentials = chartData.map((v) => v.potential).sort((a, b) => b - a);
  return (
    potentials
      .slice(0, Math.min(potentials.length, 30))
      .reduce((acc, cur) => acc + cur, 0) / 30
  );
}

export function extractSongDataForEachDifficulty(
  songData: Partial<SongData>,
  difficulty: Difficulty
): {
  constant?: number;
  notes?: number;
  level?: Level;
} {
  const d = difficulty.toLowerCase() as Lowercase<Difficulty>;
  return {
    constant: songData[`${d}Constant`],
    notes: songData[`${d}Notes`],
    level: songData[`${d}Level`],
  };
}

export function generateRowKeyFromSongId(
  id: number,
  difficulty: Difficulty
): ChartKey {
  return `${id}-${difficulty}`;
}

export function generateRowKey(chart: RowData): ChartKey {
  return `${chart.id}-${chart.difficulty}`;
}

export function querySnapshotAsObject(
  querySnapshot: QuerySnapshot<DocumentData>
): { [key: string]: ChartScoreData } {
  return querySnapshot.docs.reduce(
    (acc, doc) =>
      Object.assign(acc, { [doc.id]: doc.data() as ChartScoreData }),
    {}
  );
}

export function querySnapshotAsMap(
  querySnapshot: QuerySnapshot<DocumentData>
): Map<string, ChartScoreData> {
  return new Map(
    querySnapshot.docs.map((doc) => [doc.id, doc.data() as ChartScoreData])
  );
}

// chartDataではない(スコアは入らない)ので一旦区別
export function generateFumenData(songData: Partial<SongData>[]): {
  fumenKeys: ChartKey[];
  fumenData: { [key: ChartKey]: ChartData };
} {
  const fumenKeys: ChartKey[] = [];
  const fumenData: { [key: ChartKey]: ChartData } = {};
  songData.forEach((s) => {
    difficulties.forEach((d) => {
      const e = extractSongDataForEachDifficulty(s, d[0]); //
      if (e.level == null) return;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const key = generateRowKeyFromSongId(s.id!, d[0]);
      fumenKeys.push(key);
      fumenData[key] = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: s.id!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        jaTitle: s.jaTitle!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        enTitle: s.enTitle!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        packName: s.packName!,
        difficulty: d[0],
        level: e.level,
        constant: e.constant ?? NaN,
        notes: e.notes ?? NaN,
      };
    });
  });
  return { fumenKeys, fumenData };
}

export function generateRowKeyFromFumen(fumen: ChartData): ChartKey {
  return `${fumen.id}-${fumen.difficulty}`;
}
