import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useDatabase } from '@/di/database';
import { userAtom } from '@/states/user';

const Account: React.FC = () => {
  const user = useAtomValue(userAtom);
  const database = useDatabase();

  const [originalPublicity, setOriginalPublicity] = useState(false);
  const [publicity, setPublicity] = useState(false);

  const [originalName, setOriginalName] = useState('');
  const [name, setNewName] = useState('');

  const [nameDuplicated, setNameDuplicated] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    // initialize
    database.getUserData().then((userData) => {
      if (userData?.name != null) {
        setOriginalName(userData.name as string);
        setNewName(userData.name as string);
      }
      if (userData?.publicity != null) {
        setOriginalPublicity(userData.publicity as boolean);
        setPublicity(userData.publicity as boolean);
      }
    });
  }, [database, user]); // ←user入れないと1回目の取得に失敗する(なんかうまいことする必要がある)

  useEffect(() => {
    // username duplication check
    if (name.length === 0) return;
    if (name === originalName) return;

    database.checkUsernameExists(name).then((isExist) => {
      setNameDuplicated(isExist);
    });
  }, [database, name, originalName]);

  const submit = useCallback(async () => {
    database.setUserData({ name, publicity }, originalName).then(() => {
      setOriginalName(name);
      setOriginalPublicity(publicity);
      setShowSuccessMessage(true);
    });
  }, [database, name, originalName, publicity]);

  return (
    <Container>
      {user ? (
        <>
          {showSuccessMessage ? (
            <Alert
              severity="success"
              onClose={() => setShowSuccessMessage(false)}>
              更新に成功しました
            </Alert>
          ) : null}
          <Box my={2}>
            <Typography variant="h5">ユーザ名</Typography>
            <Typography variant="body1">
              公開する時に使用します(他のユーザと重複できません)
            </Typography>
            <TextField
              value={name}
              error={nameDuplicated}
              variant="standard"
              helperText={nameDuplicated ? '既に使用されています' : ''}
              onChange={(ev) => {
                const v = ev.target.value;
                setNewName(v);
              }}
            />
          </Box>
          <Box my={2}>
            <Typography variant="h5">公開設定</Typography>
            <FormControl>
              <RadioGroup
                value={publicity.toString()}
                onChange={(ev) => {
                  const v = ev.target.value === 'true';
                  setPublicity(v);
                }}>
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="公開する"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="公開しない"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box my={2}>
            <Button
              disabled={
                nameDuplicated ||
                name.length === 0 ||
                (originalName === name && originalPublicity === publicity)
              }
              variant="contained"
              onClick={submit}>
              保存
            </Button>
          </Box>
        </>
      ) : (
        <Typography>Login to use!</Typography>
      )}
    </Container>
  );
};
export default Account;
