import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { fumenData } from '@/data';
import { singleChartScoreDataFamily } from '@/states/derived/singleChartScoreData';
import { RowData, ChartKey } from '@/types';
import { calculatePotential } from '@/utils';

// read-only data for displaying each row
export const rowDataFamily = atomFamily((key: ChartKey) =>
  atom((get) => {
    const rowChartData = fumenData[key];
    const chartScoreData = get(singleChartScoreDataFamily(key));
    return {
      id: rowChartData.id,
      jaTitle: rowChartData.jaTitle,
      enTitle: rowChartData.enTitle,
      packName: rowChartData.packName,
      difficulty: rowChartData.difficulty,
      level: rowChartData.level,
      constant: rowChartData.constant,
      notes: rowChartData.notes,
      score: chartScoreData?.score ?? 0,
      clearLamp: chartScoreData?.clearLamp ?? 'No Play',
      potential: calculatePotential(
        rowChartData.constant,
        chartScoreData?.score ?? 0
      ),
    } as RowData;
  })
);
