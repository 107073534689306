import { atom } from 'jotai';
import { rowDataFamily } from './derived/rowData';
import { difficultyFilterAtom } from './filters/rowFilters/difficulty';
import { levelFilterAtom } from './filters/rowFilters/level';
import { rowSorterAtom } from './rowSorter';
import { fumenKeys, fumenData } from '@/data';
import { ChartKey } from '@/types';

// reduceする?
// keys of displayingChartDataListAtom, in displaying order (and filtered)
export const displayingChartKeyListAtom = atom((get) => {
  const list: ChartKey[] = [];

  const difficultyFilter = get(difficultyFilterAtom);
  const levelFilter = get(levelFilterAtom);
  const rowSorter = get(rowSorterAtom);

  fumenKeys.forEach((key) => {
    const fumen = fumenData[key];

    if (difficultyFilter[fumen.difficulty] && levelFilter[fumen.level]) {
      list.push(key);
    }
  });

  list.sort((left, right) => {
    const leftData = get(rowDataFamily(left));
    const rightData = get(rowDataFamily(right));
    for (const { comparator, asc } of rowSorter) {
      const comp = comparator(leftData, rightData) * (asc ? -1 : 1);
      if (comp !== 0) {
        return comp;
      }
    }
    return 0;
  });

  return list;
});
