import { atomFamily, atomWithDefault } from 'jotai/utils';
import { singleChartScoreDataFamily } from '@/states/derived/singleChartScoreData';
import { ChartKey } from '@/types';

// edited data for each row
// atomWithDefault: writeをカスタムできない
export const updatingScoreFamily = atomFamily((key: ChartKey) =>
  atomWithDefault((get) => {
    const scoreData = get(singleChartScoreDataFamily(key));
    return {
      score: scoreData?.score ?? 0,
      clearLamp: scoreData?.clearLamp ?? 'No Play',
    };
  })
);
