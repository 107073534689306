import { Button, FormControlLabel, FormGroup } from '@mui/material';
import { PrimitiveAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import { useAtomCallback } from 'jotai/utils';
import clone from 'lodash/clone';
import { useCallback } from 'react';
import { Checkbox } from './checkbox';

type Props<Keys extends string, Labels extends string> = {
  labels: readonly (readonly [Keys, Labels])[];
  atom: PrimitiveAtom<{ [key in Keys]: boolean }>;
};

const Checkboxes = <Keys extends string, Labels extends string>(
  props: React.PropsWithChildren<Props<Keys, Labels>>
): React.ReactElement => {
  const uncheckAll = useAtomCallback(
    useCallback(
      (get, set) => {
        const current = clone(get(props.atom));
        for (const key in current) {
          current[key] = false;
        }
        set(props.atom, current);
      },
      [props.atom]
    )
  );
  const checkAll = useAtomCallback(
    useCallback(
      (get, set) => {
        const current = clone(get(props.atom));
        for (const key in current) {
          current[key] = true;
        }
        set(props.atom, current);
      },
      [props.atom]
    )
  );

  return (
    <FormGroup row>
      {props.labels.map((label, i) => (
        <FormControlLabel
          key={i}
          label={label[1]}
          control={
            <Checkbox
              atom={focusAtom(props.atom, (optic) => optic.prop(label[0]))}
            />
          }
        />
      ))}
      <Button variant="outlined" onClick={uncheckAll}>
        すべてOFF
      </Button>
      <Button variant="outlined" onClick={checkAll}>
        すべてON
      </Button>
    </FormGroup>
  );
};

export default Checkboxes;
